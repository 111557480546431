import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useController, useForm } from 'react-hook-form'
import Input from 'src/components/simple/Input'
import { Gray, Purple } from 'src/constants/colors'
import { WAITLIST_CODE_STATUS } from 'src/constants/waitlist-code'

const FullWidthContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0%;
  top: 0%;
  transform: translate(-50%, -50);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  width: 369px;
  background-color: #262627;
  border-radius: 25px;
  padding: 43px 73px 43px 73px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  position: relative;
`

const Form = styled.form`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 223px;
  padding: 10px;
`

const InputSubtitle = styled.p`
  margin-bottom: 7px;
  align-self: flex-start;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  gap: 10px;
`

const SaveButton = styled.button`
  width: 88px;
  height: 44px;
  border: none;
  border-radius: 5px;
  background-color: ${({ disabled }) => (disabled ? Gray : Purple)};
  color: white;
  font-weight: 400;
  cursor: pointer;
`

const CancelButton = styled.button`
  width: 88px;
  height: 44px;
  border: 2px solid ${({ disabled }) => (disabled ? Gray : Purple)};
  border-radius: 5px;
  background-color: transparent;
  color: ${({ disabled }) => (disabled ? Gray : Purple)};
  font-weight: 400;
  cursor: pointer;
`

const StyledInput = styled(Input)`
  height: 27px;
  min-width: 100%;
  background-color: ${({ disabled }) => (disabled ? Gray : '#c447e01a')};
  border-radius: 0px;
  font-size: 15px;
`

const Text = styled.p`
  display: flex;
  align-items: center;
  height: 27px;
  min-width: 100%;
  font-size: 15px;
`

const Error = styled.p`
  padding: 5px 0 0 10px;
  color: ${({ theme }) => theme.Error};
`

const WaitlistCodeModal = memo(
  ({ activeCode, onCancel, onSubmit, disabled }) => {
    const { control, handleSubmit } = useForm({
      mode: 'onChange',
    })
    const { field, fieldState } = useController({
      name: 'isActive',
      control,
      defaultValue:
        activeCode?.isActive || activeCode?.isActive === undefined
          ? WAITLIST_CODE_STATUS.ACTIVE
          : WAITLIST_CODE_STATUS.INACTIVE,
      rules: { required: true },
    })

    const renderContent = () => (
      <Container disabled={disabled}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <InputSubtitle>Code</InputSubtitle>
            {activeCode ? (
              <Text>{activeCode?.code}</Text>
            ) : (
              <StyledInput
                required
                name="code"
                placeholder="Enter Code"
                type="Text"
                control={control}
                maxLength={20}
                disabled={disabled}
              />
            )}
          </InputContainer>
          <InputContainer>
            <InputSubtitle>usesLeft</InputSubtitle>
            <StyledInput
              required
              name="usesLeft"
              placeholder="Enter Code"
              type="Text"
              control={control}
              defaultValue={activeCode?.usesLeft}
              validate={value => value >= 0 || 'value should be a number'}
              maxLength={7}
              disabled={disabled}
            />
          </InputContainer>
          <InputContainer>
            <InputSubtitle>Status</InputSubtitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'flex-start',
              }}
              {...field}
              onChange={e => field.onChange(e.target.value)}
            >
              <label>
                <input
                  type="radio"
                  value={WAITLIST_CODE_STATUS.ACTIVE}
                  name="isActive"
                  defaultChecked={
                    activeCode?.isActive || activeCode?.isActive === undefined
                  }
                  disabled={disabled}
                />
                Active
              </label>
              <label>
                <input
                  type="radio"
                  value={WAITLIST_CODE_STATUS.INACTIVE}
                  name="isActive"
                  defaultChecked={activeCode?.isActive === false}
                  disabled={disabled}
                />
                Inactive
              </label>
              {fieldState.error && (
                <Error>{fieldState.error?.message || 'Required'}</Error>
              )}
            </div>
          </InputContainer>
          <ButtonContainer>
            <SaveButton type="submit" disabled={disabled}>
              Save
            </SaveButton>
            <CancelButton type="button" disabled={disabled} onClick={onCancel}>
              Cancel
            </CancelButton>
          </ButtonContainer>
        </Form>
      </Container>
    )

    return (
      <FullWidthContainer
        initial={{ top: '100%' }}
        animate={{ top: '0%' }}
        exit={{ top: '100%' }}
      >
        {renderContent()}
      </FullWidthContainer>
    )
  }
)

WaitlistCodeModal.propTypes = {
  activeCode: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired, // eslint-disable-line react/forbid-prop-types
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.func.isRequired,
}

export default WaitlistCodeModal
