import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTable } from 'react-table'
import { Purple } from 'src/constants/colors'
import pencil from 'src/images/pencil.png'
import plus from 'src/images/plus.png'
import { WAITLIST_CODE_STATUS } from 'src/constants/waitlist-code'

const Table = styled.table`
  width: 100%;
  margin-top: 40px;
  border-collapse: collapse;

  thead {
    th {
      padding: 0px 0px 10px 5px;
      text-align: left;
      border-bottom: solid 1px white;
    }
  }

  tbody {
    tr {
      font-size: 15px;
    }

    td {
      font-size: 15px;
      padding: 10px 0px 10px 10px;
    }

    tr:nth-child(odd) {
      background-color: #181818;
    }

    td:first-child {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }

    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
`

const EditIcon = styled.div`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  min-width: 10px;
  margin-left: auto;
  margin-right: 13px;
  visibility: ${({ rowCode, activeCode }) =>
    rowCode === activeCode ? 'visible' : 'hidden'};
  cursor: pointer;
`

const PlusIcon = styled.div`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  min-width: 10px;
  margin-left: auto;
  margin-right: 13px;
  cursor: pointer;
`

const ActiveBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  border: 1px solid white;
  border-radius: 20px;
  margin-right: 5px;
`
const ActiveBadge = styled.div`
  width: 11px;
  height: 11px;
  border: 1px solid transparent;
  border-radius: 20px;
  background-clip: padding-box;
  background-color: ${({ status }) =>
    status === WAITLIST_CODE_STATUS.ACTIVE ? '#76fb4d' : 'red'};
`

const WaitlistCodeTable = ({ columns, data, activeCode, onSelect, onEdit }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })

  return (
    <Table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                <p>{column.render('Header')}</p>
              </th>
            ))}
            <th>
              <PlusIcon
                src={plus}
                onClick={() => {
                  onSelect('')
                  onEdit()
                }}
              />
            </th>
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)

          const makeRowStyle = () => {
            const style = {}
            if (activeCode === row.original.code) {
              style.backgroundColor = Purple
            }
            return style
          }

          return (
            <tr
              {...row.getRowProps()}
              style={makeRowStyle()}
              onClick={() => onSelect(row.original)}
            >
              {row.cells.map(cell => {
                if (cell.column.Header === 'Status') {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <ActiveBadgeContainer>
                        <ActiveBadge status={cell.value} />
                      </ActiveBadgeContainer>
                      {cell.render('Cell')}
                    </td>
                  )
                }
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
              <td>
                <EditIcon
                  src={pencil}
                  rowCode={row.original?.code}
                  activeCode={activeCode}
                  onClick={onEdit}
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

WaitlistCodeTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  activeCode: PropTypes.string,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
}

export default WaitlistCodeTable
