/**
 * @file Inventory Menu
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DarkerGray } from 'src/constants/colors'
import useCreateEventSeriesInstance from 'src/hooks/query/event/useCreateEventSeriesInstance'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'
import FormItem from '../molecules/FormItem'
import DateInput from '../molecules/inputs/DateInput'
import Button from '../atoms/Button'

const Container = styled.div`
  padding: 40px;
  background-color: ${DarkerGray};
  width: 410px;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: auto;
`

const Form = styled.form`
  margin-top: 20px;
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const EventSeriesInstanceMenu = ({ eventSeries }) => {
  const createEventSeriesInstanceMutation = useCreateEventSeriesInstance(
    eventSeries?.id
  )
  const { control, handleSubmit, isSubmitting, reset } = useForm()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleBackPress = () => {
    searchParams.delete('showInstancePanel')
    setSearchParams(searchParams)
  }

  const onSubmit = async values => {
    const toastID = toast.loading('Loading')

    try {
      await createEventSeriesInstanceMutation.mutateAsync(values)
      toast.success('Success', { id: toastID })
      reset()
      searchParams.delete('showInstancePanel')
      setSearchParams(searchParams)
    } catch (e) {
      toast.error('Failed', { id: toastID })
    }
  }

  return (
    <Container>
      <TitleContainer>
        <ArrowLeft
          onClick={handleBackPress}
          style={{ cursor: 'pointer' }}
          size={32}
        />
        <h5 style={{ textAlign: 'center' }}>
          Add Instance to: {eventSeries?.title} Series
        </h5>
      </TitleContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <>
          <FormItem
            title="Start Date"
            input={
              <DateInput
                placeholder="Select Date"
                name="startDate"
                defaultValue=""
                control={control}
              />
            }
          />
          <FormItem
            title="End Date"
            input={
              <DateInput
                placeholder="Select Date"
                name="endDate"
                defaultValue=""
                control={control}
              />
            }
          />
        </>
        <Button disabled={isSubmitting}>Submit</Button>
      </Form>
    </Container>
  )
}

EventSeriesInstanceMenu.propTypes = {
  eventSeries: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    website: PropTypes.string,
    ticketLink: PropTypes.string,
    location: PropTypes.shape({
      formatted: PropTypes.string,
    }),
    minTicketPrice: PropTypes.number,
    maxTicketPrice: PropTypes.number,
    rsvpRequired: PropTypes.bool,
    ticketsRequired: PropTypes.bool,
    isSeries: PropTypes.bool,
  }),
}

export default EventSeriesInstanceMenu
