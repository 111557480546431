/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Button from 'src/componentsV2/atoms/Button'
import useAcceptWaitlistedUser from 'src/hooks/query/user/useAcceptWaitlistedUser'
import toast from 'react-hot-toast'

const FullWidthContainer = styled(motion.div)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 40px;
  display: flex;
  justify-content: center;
  pointer-events: none;
`

const Container = styled.div`
  padding: 24px 30px;
  background-color: #262627;
  border-radius: 12px;
  pointer-events: all;
  display: flex;
  flex-direction: column;

  > h3 {
    padding-bottom: 12px;
  }

  > p {
    padding-bottom: 5px;
  }
`

const WaitlistUserModal = ({ user }) => {
  const acceptWaitlistedUserMutation = useAcceptWaitlistedUser()

  const handleAcceptClick = async () => {
    const toastID = toast.loading('Accepting User')
    try {
      await acceptWaitlistedUserMutation.mutateAsync(user?.id)
      toast.success('Accepted!', { id: toastID })
    } catch (e) {
      toast.error('Failed', { id: toastID })
    }
  }

  return (
    <FullWidthContainer>
      <Container>
        <h3>User Info</h3>
        <p>{user?.id}</p>
        <p>{user?.name}</p>
        <Button
          size="small"
          style={{ alignSelf: 'center', marginTop: 20 }}
          onClick={handleAcceptClick}
        >
          Accept User
        </Button>
      </Container>
    </FullWidthContainer>
  )
}

WaitlistUserModal.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
}

export default WaitlistUserModal
