/**
 * @file STL Table
 * @author Alwyn Tan
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import useGetSTLs from 'src/hooks/query/stl/useGetSTLs'
import Table from '../molecules/Table'

const Container = styled.div``

const EndOfListText = styled.p`
  padding-left: 10px;
  padding-top: 20px;
`

const STLTable = () => {
  const infiniteTriggerRef = useRef(null)
  const [activeSTL, setActiveSTL] = useState('')
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useGetSTLs()
  const [, setSearchParams] = useSearchParams()

  const stls = data || []

  const handleSTLSelect = useCallback(
    stl => {
      setActiveSTL(stl)
      setSearchParams({ selected: stl.id })
    },
    [setActiveSTL, setSearchParams]
  )

  useEffect(() => {
    if (hasNextPage && infiniteTriggerRef.current && !isFetchingNextPage) {
      const observer = new IntersectionObserver(
        entries =>
          entries.forEach(entry => entry.isIntersecting && fetchNextPage()),
        { rootMargin: '0px', threshold: 1.0 }
      )

      const el = infiniteTriggerRef.current

      observer.observe(el)
      return () => observer.unobserve(el)
    }
    return () => {}
  }, [hasNextPage, fetchNextPage, isFetchingNextPage])

  const columns = useMemo(
    () => [
      {
        Header: 'Poster',
        accessor: 'poster',
        // eslint-disable-next-line
        Cell: ({ cell: { value } }) => (
          // eslint-disable-next-line
          <img src={value} width={25} height={25} />
        ),
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Details',
        accessor: 'description',
      },
      {
        Header: 'Address',
        accessor: 'location.formatted',
      },
      {
        Header: 'Next Available Start Date',
        accessor: v =>
          v.nextAvailableStartDate &&
          dayjs(v.nextAvailableStartDate).tz().format('DD MMM YYYY h:mma z'),
      },
      {
        Header: 'Next Available End Date',
        accessor: v =>
          v.nextAvailableEndDate &&
          dayjs(v.nextAvailableEndDate).tz().format('DD MMM YYYY h:mma z'),
      },
      {
        Header: 'Single Pass Price',
        accessor: v => (v.price ? `$${v.price / 100}` : 0),
      },
    ],
    []
  )

  return (
    <Container>
      <Table
        style={{ minWidth: 1200 }}
        columns={columns}
        data={stls}
        activeID={activeSTL.id}
        onSelect={handleSTLSelect}
      />
      {!hasNextPage && (
        <EndOfListText>That's it! Total: {data?.length} rows</EndOfListText>
      )}
      <div ref={infiniteTriggerRef} />
    </Container>
  )
}

export default STLTable
