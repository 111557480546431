/**
 * @file Get drops query
 * @author Alwyn Tan
 */

import { useInfiniteQuery } from 'react-query'
import { dropKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const getDrops = async (status, prevItem) => {
  const searchParams = { sortBy: 'default', status }
  if (prevItem?.id) searchParams.prevID = prevItem?.id
  if (prevItem?.activeDate) searchParams.prevActiveDate = prevItem?.activeDate

  return api.get('drop', { searchParams }).json()
}

const useGetDrops = status =>
  useInfiniteQuery(
    dropKeys.list(status),
    async ({ pageParam }) => getDrops(status, pageParam),
    {
      select: data =>
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.drops], []),
      getNextPageParam: prevPage => {
        if (prevPage?.canLoadMore)
          return prevPage?.drops[(prevPage?.drops.length || 0) - 1]
        return undefined
      },
    }
  )

export default useGetDrops
