/**
 * @file Horizontal Nav Item Component for our pages
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Text from '../atoms/Text'

const StyledNavLink = styled(NavLink)`
  margin-right: 30px;
  color: white;
  text-decoration: none;
  opacity: 0.4;

  &.active {
    opacity: 1;
  }
`

const PageNavItem = ({ to = '', title = '' }) => (
  <StyledNavLink to={to}>
    <Text type="h3">{title}</Text>
  </StyledNavLink>
)

PageNavItem.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
}

export default PageNavItem
