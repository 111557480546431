/**
 * @file Updates a STL item
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from 'react-query'
import { stlKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const updateSTL = async (id, fields) =>
  api.put(`stl/${id}`, { json: fields }).json()

const useUpdateSTL = id => {
  const queryClient = useQueryClient()

  return useMutation(async fields => updateSTL(id, fields), {
    onSuccess: () => {
      queryClient.invalidateQueries(stlKeys.list())
    },
  })
}

export default useUpdateSTL
