/**
 * @file Google service to help with google related requests
 * @author Alwyn Tan
 */

import { Loader } from '@googlemaps/js-api-loader'

const BASIC_FIELDS = [
  'address_component',
  'adr_address',
  'business_status',
  'formatted_address',
  'geometry',
  'icon',
  'name',
  'photos',
  'place_id',
  'plus_code',
  'type',
  'url',
  'utc_offset_minutes',
  'vicinity',
]

const GoogleService = (() => {
  let google

  return {
    initialize: async () => {
      google = await new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: ['places'],
      }).load()
    },

    initializeMap: (mapElem, params) => {
      if (!google) throw new Error('Google Service not initialized')
      return new google.maps.Map(mapElem, params)
    },

    getAutocompleteSessionToken: () =>
      new google.maps.places.AutocompleteSessionToken(),

    getPlacePredictions: async params => {
      if (!google) throw new Error('Google Service not initialized')
      const autocompleteService = new google.maps.places.AutocompleteService()
      return autocompleteService.getPlacePredictions(params)
    },

    getPlaceDetails: async (map, params) => {
      const placesService = new google.maps.places.PlacesService(
        map || document.createElement('div')
      )

      return new Promise(resolve => {
        placesService.getDetails(
          { ...params, fields: BASIC_FIELDS },
          response => resolve(response)
        )
      })
    },

    createMarker: params => {
      if (!google) throw new Error('Google Service not initialized')
      return new google.maps.Marker(params)
    },

    parseAddressComponents: addressComponents => {
      let streetNumber = ''
      let streetAddress = ''
      let zip = ''
      let locality = ''
      let region = ''
      let country = ''

      for (const component of addressComponents) {
        const componentType = component.types[0]

        switch (componentType) {
          case 'street_number': {
            streetNumber = component.short_name
            break
          }
          case 'route': {
            streetAddress = component.short_name
            break
          }
          case 'postal_code': {
            zip = component.short_name
            break
          }
          case 'locality':
            locality = component.short_name
            break
          case 'administrative_area_level_1': {
            region = component.short_name
            break
          }
          case 'country':
            country = component.short_name
            break
          default:
            break
        }
      }

      return { streetNumber, streetAddress, zip, locality, region, country }
    },
  }
})()

export default GoogleService
