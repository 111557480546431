/**
 * @file Creates a STL item
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from 'react-query'
import { stlKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const createSTL = async fields =>
  api.post('stl', { json: { ...fields } }).json()

const useCreateSTL = () => {
  const queryClient = useQueryClient()

  return useMutation(async fields => createSTL(fields), {
    onSuccess: () => {
      queryClient.invalidateQueries(stlKeys.list())
    },
  })
}

export default useCreateSTL
