/**
 * @file Creates a scheduled notification
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from 'react-query'
import { notificationKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const createScheduledNotification = async fields =>
  api.post('notification/schedule', { json: fields }).json()

const useCreateScheduledNotification = () => {
  const queryClient = useQueryClient()

  return useMutation(async fields => createScheduledNotification(fields), {
    onSuccess: () => {
      queryClient.invalidateQueries(notificationKeys.lists())
    },
  })
}

export default useCreateScheduledNotification
