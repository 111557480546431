/**
 * @file Get bookingV2s query
 * @author Alwyn Tan
 */

import axios from 'axios'
import { useInfiniteQuery } from 'react-query'
import { bookingV2Keys } from 'src/query-key-factory'

const getBookingV2s = async (params, pageParam) =>
  (
    await axios.get('/order/v2/booking', {
      params: { ...params, showAllUsers: true, ...pageParam },
    })
  ).data

const useGetBookingV2s = (params = {}) =>
  useInfiniteQuery(
    bookingV2Keys.list({ ...params }),
    async ({ pageParam }) => getBookingV2s(params, pageParam),
    {
      select: data =>
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.bookingV2s], []),
      getNextPageParam: prevPage => {
        if (prevPage.canLoadMore) {
          const prevBookingV2s =
            prevPage.bookingV2s[(prevPage?.bookingV2s.length || 0) - 1]
          return {
            prevID: prevBookingV2s?.id,
          }
        }
        return undefined
      },
    }
  )

export default useGetBookingV2s
