/**
 * @file Promo Notification Table
 * @author Alwyn Tan
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import useGetScheduledNotifications from 'src/hooks/query/notification/useGetScheduledNotifications'
import Table from '../molecules/Table'

const Container = styled.div``

const EndOfListText = styled.p`
  padding-left: 10px;
  padding-top: 20px;
`

const PromoNotificationTable = () => {
  const infiniteTriggerRef = useRef(null)
  const [activeItem, setActiveItem] = useState('')
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useGetScheduledNotifications()
  const [, setSearchParams] = useSearchParams()

  const jobs = data || []

  const handleSelect = useCallback(
    item => {
      setActiveItem(item)
      setSearchParams({ selected: item.id })
    },
    [setActiveItem, setSearchParams]
  )

  useEffect(() => {
    if (hasNextPage && infiniteTriggerRef.current && !isFetchingNextPage) {
      const observer = new IntersectionObserver(
        entries =>
          entries.forEach(entry => entry.isIntersecting && fetchNextPage()),
        { rootMargin: '0px', threshold: 1.0 }
      )

      const el = infiniteTriggerRef.current

      observer.observe(el)
      return () => observer.unobserve(el)
    }
    return () => {}
  }, [hasNextPage, fetchNextPage, isFetchingNextPage])

  const columns = useMemo(
    () => [
      {
        Header: 'Scheduled For',
        accessor: v =>
          v.nextRunAt && dayjs(v.nextRunAt).tz().format('DD MMM YYYY h:mma z'),
      },
      { Header: 'Title', accessor: 'title' },
      { Header: 'Message', accessor: 'message' },
    ],
    []
  )

  return (
    <Container>
      <Table
        style={{ minWidth: 1200 }}
        columns={columns}
        data={jobs}
        activeID={activeItem.id}
        onSelect={handleSelect}
      />
      {!hasNextPage && (
        <EndOfListText>That's it! Total: {data?.length} rows</EndOfListText>
      )}
      <div ref={infiniteTriggerRef} />
    </Container>
  )
}

export default PromoNotificationTable
