/**
 * @file Skip the line page
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import Button from 'src/componentsV2/atoms/Button'
import useGetSTL from 'src/hooks/query/stl/useGetSTL'
import STLDetailsMenu from '../organisms/STLDetailsMenu'
import STLInventoryMenu from '../organisms/STLInventoryMenu'
import STLTable from '../templates/STLTable'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  padding: 40px 0px 0px 40px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
`

const Title = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  padding-right: 40px;
`

const STL = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleAddClick = () => {
    setSearchParams({ new: true })
  }

  const currentID = searchParams.get('selected')
  const showInventory = searchParams.get('showInventory')
  const isNew = searchParams.get('new')

  const { data } = useGetSTL(currentID)
  const stl = data?.stl

  return (
    <Container>
      <Content>
        <Header>
          <Title>
            <h2 style={{ marginRight: 20 }}>Skip The Line</h2>
            <Button
              size="medium"
              onClick={handleAddClick}
              style={{ flex: 1, maxWidth: 100 }}
            >
              + Add
            </Button>
          </Title>
        </Header>
        <InnerContainer>
          <STLTable />
        </InnerContainer>
      </Content>
      {!showInventory && currentID && <STLDetailsMenu stl={stl} />}
      {isNew && <STLDetailsMenu />}
      {showInventory && currentID && <STLInventoryMenu stlID={currentID} />}
    </Container>
  )
}

export default STL
