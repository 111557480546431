/**
 * @file Login Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import AuthContext from 'src/contexts/AuthContext'
import api from 'src/utils/api'

const postLogin = async ({ phoneNumber, code }) =>
  api
    .post('user/login', {
      json: { phoneNumber, code, admin: true },
      credentials: 'include',
    })
    .json()

const useLogin = () => {
  const { setUser } = useContext(AuthContext)

  return useMutation(async ({ phoneNumber, code }) => {
    const { accessToken, user } = await postLogin({ phoneNumber, code })
    setUser({ ...user, accessToken })

    return { user }
  })
}

export default useLogin
