/**
 * @file Drop Search Bar
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DarkerGray } from 'src/constants/colors'
import AsyncSelect from 'react-select/async'
import { debounce } from 'lodash'
import { Search } from 'react-feather'
import api from 'src/utils/api'

const customStyles = {
  container: provided => ({
    ...provided,
  }),
  control: provided => ({
    ...provided,
    backgroundColor: '#ffffff1a',
    border: 0,
    height: 40,
    width: 335,
    padding: 0,
    paddingLeft: 30,
    borderRadius: 20,
    boxShadow: 'none',
  }),
  input: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    opacity: 0.4,
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    padding: 15,
    backgroundColor: state.isFocused ? DarkerGray : 'transparent',

    ':active': {
      ...provided[':active'],
      backgroundColor: state.isFocused ? DarkerGray : 'transparent',
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: DarkerGray,
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: 'white',
    fontStyle: 'normal',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '0.8rem',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: DarkerGray,
    borderRadius: 8,
  }),
  menuList: provided => ({
    ...provided,
    borderRadius: 8,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    display: 'none',
  }),
}

const Container = styled.div`
  position: relative;
  z-index: 9;
`

const CustomOption = styled.div`
  display: flex;
  align-items: center;

  > img {
    width: 25px;
    height: 46px;
    object-fit: cover;
    margin-right: 20px;
  }
`

const DropSearchBar = ({ placeholder = 'Search', onSelect = () => {} }) => {
  const loadOptions = (input, cb) => {
    // debounce the request, we cannot memoize this function as "cb" is regenerated on each keystroke, so we can't optimize it here, but can optimize the server calls.
    debounce(async () => {
      const { drops } = await api
        .get('search/drops', {
          searchParams: {
            q: input,
            showUnreleased: true,
            showDraft: true,
            showExpired: true,
          },
        })
        .json()

      const options = drops?.map(curr => ({
        label: curr.title,
        value: curr.id,
        thumbnail: curr.thumbnail,
      }))

      cb(options)
    }, 300)()
  }

  const handleOnChange = v => {
    onSelect(v.value)
  }

  const renderCustomOption = ({ label, thumbnail }, { context }) =>
    context === 'menu' ? (
      <CustomOption>
        <img src={thumbnail} alt="thumbnail" />
        <p>{label}</p>
      </CustomOption>
    ) : (
      <p>{label}</p>
    )

  return (
    <Container>
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        styles={customStyles}
        placeholder={placeholder}
        onChange={handleOnChange}
        formatOptionLabel={renderCustomOption}
      />
      <Search
        size={16}
        style={{ opacity: 0.5, position: 'absolute', left: 15, top: 12 }}
      />
    </Container>
  )
}

DropSearchBar.propTypes = {
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
}

export default DropSearchBar
