/**
 * @file Get Waitlist Codes query
 * @author Jordan Stipp
 */

import { useInfiniteQuery } from 'react-query'
import { waitlistCodeKeys } from 'src/query-key-factory'
import { WAITLIST_CODE_STATUS } from 'src/constants/waitlist-code'
import api from 'src/utils/api'

const getWaitlistCodes = async (prevItem, status) => {
  const searchParams = { isActive: status === WAITLIST_CODE_STATUS.ACTIVE }
  if (prevItem?.code) searchParams.prevCode = prevItem?.code
  return api.get('waitlist-code/', { searchParams }).json()
}

const useGetWaitlistCodes = status =>
  useInfiniteQuery(
    waitlistCodeKeys.list(status),
    async ({ pageParam }) => getWaitlistCodes(pageParam, status),
    {
      select: data =>
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.waitlistCodes], []),
      getNextPageParam: prevPage => {
        if (prevPage?.canLoadMore)
          return prevPage?.waitlistCodes[
            (prevPage?.waitlistCodes.length || 0) - 1
          ]
        return undefined
      },
    }
  )
export default useGetWaitlistCodes
