/**
 * @file Updates an EventSeriesInstance
 * @author Jordan Stipp
 */

import { serialize } from 'object-to-formdata'
import { useMutation, useQueryClient } from 'react-query'
import { eventKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const updateEventSeriesInstance = async (id, fields) =>
  api
    .put(`event/series-instance/${id}`, {
      body: serialize(fields, { indices: true }),
      timeout: 60000,
      prefixUrl: process.env.REACT_APP_API_URL,
    })
    .json()

const useUpdateEventSeriesInstance = (id, seriesID) => {
  const queryClient = useQueryClient()

  return useMutation(async fields => updateEventSeriesInstance(id, fields), {
    onSuccess: () => {
      queryClient.invalidateQueries(eventKeys.detail(id))
      queryClient.invalidateQueries(eventKeys.detail(seriesID))
    },
  })
}

export default useUpdateEventSeriesInstance
