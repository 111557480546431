/**
 * @file Booking Tab
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from 'src/componentsV2/atoms/Text'
import InfiniteScroll from 'src/components/compound/InfiniteScroll'
import useGetBookingV2s from 'src/hooks/query/bookingV2/useGetBookingV2s'
import BookingV2ListItem from 'src/componentsV2/organisms/BookingV2/BookingV2ListItem'
import BookingDetails from 'src/componentsV2/organisms/BookingV2/BookingDetails'
import { useSearchParams } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  gap: 60px;
`

const Column = styled.div``

const BookingTabV2 = ({ status }) => {
  const [, setSearchParams] = useSearchParams()

  return (
    <Container>
      <Column>
        <Text type="h4" style={{ marginBottom: 16 }}>
          Bookings
        </Text>
        <InfiniteScroll
          infiniteQueryHook={useGetBookingV2s}
          infiniteQueryHookParams={[{ status, populate: ['user'] }]}
          endOfListComponent={<Text type="p1">No more bookings</Text>}
        >
          {bookingV2 => (
            <BookingV2ListItem
              key={bookingV2.id}
              bookingV2={bookingV2}
              onViewClick={() =>
                setSearchParams({ bookingV2ID: bookingV2?.id })
              }
            />
          )}
        </InfiniteScroll>
      </Column>
      <Column>
        <BookingDetails />
      </Column>
    </Container>
  )
}

BookingTabV2.propTypes = {
  status: PropTypes.string,
}

export default BookingTabV2
