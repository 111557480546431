/**
 * @file Text Area Component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useController } from 'react-hook-form'
import { Red } from 'src/constants/colors'
import TextareaAutosize from 'react-textarea-autosize'

const Container = styled.div`
  border-radius: 8px;
  background-color: #ffffff1a;
  max-width: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px;
  border: 4px solid ${Red};
  border-color: ${({ error }) => (error ? Red : 'transparent')};
  border-radius: ${({ error }) => (error ? '8px 8px 0 0' : '8px')};
  min-height: 50px;

  > textarea {
    resize: none;
    background-color: transparent;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    outline: none;
    border: 0;
    height: 100%;
    width: 100%;
    color: white;
  }
`

const Error = styled.div`
  background-color: ${Red};
  padding: 5px 15px 10px 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
`

const TextAreaInput = ({
  disabled = false,
  loading = false,
  required = false,
  textAreaProps = {},
  placeholder = '',
  defaultValue,
  control,
  name,
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
    rules: { required },
  })

  return (
    <>
      <Container disabled={disabled} error={fieldState.error}>
        <TextareaAutosize
          {...textAreaProps}
          placeholder={placeholder}
          disabled={disabled || loading}
          {...field}
        />
      </Container>
      {fieldState.error && (
        <Error>
          <span>{fieldState.error.message}</span>
        </Error>
      )}
    </>
  )
}

TextAreaInput.propTypes = {
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  textAreaProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

export default TextAreaInput
