/**
 * @file STL Inventory Menu
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { DarkerGray, Purple } from 'src/constants/colors'
import useGetSTLInventories from 'src/hooks/query/stl/useGetSTLInventories'
import useGetSTL from 'src/hooks/query/stl/useGetSTL'
import STLInventoryCard from '../molecules/STLInventoryCard'
import NewSTLInventoryCard from '../molecules/NewSTLInventoryCard'

const Container = styled.div`
  padding: 40px;
  background-color: ${DarkerGray};
  width: 410px;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: auto;
`

const AddButton = styled.p`
  cursor: pointer;
`

// todo: make sure the inventory loading is supported for infinite loading
const STLInventoryMenu = ({ stlID }) => {
  const [showNew, setShowNew] = useState(false)
  const [activeInventoryID, setActiveInventoryID] = useState(null)
  const { data: stlData } = useGetSTL(stlID)
  const { data: stlInventoryData } = useGetSTLInventories(stlID)

  const stl = stlData?.stl
  const inventories = stlInventoryData || []

  const renderInventories = () => {
    const items = []

    // split them by day
    let prevStartDate
    for (const i of inventories) {
      if (
        !prevStartDate ||
        dayjs(i.startDate).tz().day() !== dayjs(prevStartDate).tz().day()
      )
        items.push(
          <h5
            key={dayjs(i.startDate).tz().format('MMM DD')}
            style={{ marginBottom: 15, marginTop: 10 }}
          >
            {dayjs(i.startDate).tz().format('MMM DD')}
          </h5>
        )

      items.push(
        <STLInventoryCard
          stlInventory={i}
          key={i.id}
          style={{ marginBottom: 10 }}
          active={i.id === activeInventoryID}
          onClick={() => setActiveInventoryID(i.id)}
          onCancel={() => setActiveInventoryID(null)}
        />
      )
      prevStartDate = i.startDate
    }

    return items
  }

  return (
    <Container>
      <h4>{stl?.title} inventory</h4>
      {!showNew && (
        <AddButton
          className="button2"
          style={{ color: Purple, marginTop: 10 }}
          onClick={() => setShowNew(true)}
        >
          + Add
        </AddButton>
      )}
      {showNew && (
        <NewSTLInventoryCard stlID={stlID} onCancel={() => setShowNew(false)} />
      )}
      {renderInventories()}
    </Container>
  )
}

STLInventoryMenu.propTypes = {
  stlID: PropTypes.string.isRequired,
}

export default STLInventoryMenu
