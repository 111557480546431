/**
 * @file New Inventory V2 card
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from 'src/componentsV2/atoms/Text'

const Container = styled.div`
  width: 100%;
  background: #ffffff0d;
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const DealContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  gap: 8px;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
`

const InventoryV2Card = ({ inventory }) => (
  <Container>
    <Text type="button2">{inventory.title}</Text>
    {inventory?.description && <Text type="p1">{inventory.description}</Text>}
    {inventory?.deal && (
      <DealContainer>
        <Text type="button2">Included:</Text>
        <Text type="button2">{inventory.deal.title}</Text>
        {inventory.deal.value && (
          <Text type="p2" style={{ opacity: 0.5 }}>
            (${inventory.deal.value / 100} value)
          </Text>
        )}
      </DealContainer>
    )}
    <Row>
      <Text type="button2">${(inventory.price / 100).toFixed(2)}</Text>
      <Text type="p1" style={{ paddingLeft: 10 }}>
        ({inventory.quantity} remaining)
      </Text>
    </Row>
  </Container>
)

InventoryV2Card.propTypes = {
  inventory: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    deal: PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.number,
    }),
    price: PropTypes.number,
    quantity: PropTypes.number,
  }),
}

export default InventoryV2Card
