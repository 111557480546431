/**
 * @file Accepts and confirms a pending pass
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from 'react-query'
import { passKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const postConfirmPass = async (passID, confirmationLink) =>
  api.post('pass/confirm', { json: { id: passID, confirmationLink } }).json()

const useConfirmPass = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ passID, confirmationLink }) =>
      postConfirmPass(passID, confirmationLink),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(passKeys.lists())
      },
    }
  )
}

export default useConfirmPass
