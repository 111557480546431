/**
 * @file Reusable table component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTable } from 'react-table'
import dayjs from 'dayjs'
import { OffBlack, Purple } from 'src/constants/colors'

const StyledTable = styled.table`
  margin-top: 40px;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    th {
      position: sticky;
      top: 0;
      padding: 10px 0;
      border-bottom: solid 1px white;
      text-align: left;
      background-color: ${OffBlack};
      z-index: 1;
    }

    th:first-child {
      padding-left: 10px;
    }

    th:last-child {
      padding-right: 10px;
    }
  }

  tbody {
    td {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    td:first-child {
      padding-left: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      padding-right: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    tr {
      cursor: pointer;
    }

    tr:nth-child(even) {
      > td {
        background: #ffffff1a;
      }
    }
  }
`

const Table = ({
  style = {},
  columns,
  data,
  activeID = '',
  onSelect = () => {},
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })

  return (
    <StyledTable {...getTableProps()} style={style}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                <p>{column.render('Header')}</p>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)

          const makeRowStyle = () => {
            const rowStyle = {}

            if (activeID === row.original.id) rowStyle.background = Purple
            if (
              row.original.endDate &&
              dayjs(row.original.endDate).isBefore(dayjs())
            )
              rowStyle.opacity = 0.4

            return rowStyle
          }

          return (
            <tr
              {...row.getRowProps()}
              style={makeRowStyle()}
              onClick={() => onSelect(row.original)}
            >
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

Table.propTypes = {
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  activeID: PropTypes.string,
  onSelect: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
}

export default Table
