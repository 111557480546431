import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import AuthContext from './contexts/AuthContext'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import GoogleService from './services/google'

const queryClient = new QueryClient()

const theme = {
  Error: '#ff0033',
  Action: '#9E24BF',
}

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.tz.setDefault('America/New_York')

// Setup global axios here - might want to pull it out to a separate file
/* setup all the axios defaults */
axios.defaults.baseURL = '/api'

// interceptor to auto silent login when initializing
axios.interceptors.request.use(async req => {
  const hasRefreshToken = document.cookie.indexOf('rtExists=') !== -1
  const isLoginRequest =
    req?.url?.endsWith('/user/silent-login') ||
    req?.url?.endsWith('/user/login')

  if (
    !axios.defaults.headers.common.Authorization &&
    hasRefreshToken &&
    !isLoginRequest
  ) {
    const { accessToken } = (
      await axios.post(`/user/silent-login`, {}, { withCredentials: true })
    ).data

    if (accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      req.headers.Authorization = `Bearer ${accessToken}`
    }
  }

  return req
})

// interceptor to auto retry login accessToken fails
axios.interceptors.response.use(null, async error => {
  const originalRequest = error.config

  if (
    originalRequest?.url !== '/user/silent-login' &&
    originalRequest?.url !== '/user/login' && // extra precaution to not retry logins
    !originalRequest?.retried &&
    error.response?.status === 401
  ) {
    delete axios.defaults.headers.common.Authorization
    originalRequest.retried = true

    const { accessToken } = (
      await axios.post(`/user/silent-login`, {}, { withCredentials: true })
    ).data

    if (accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      originalRequest.headers.Authorization = `Bearer ${accessToken}`
    }

    return axios(originalRequest)
  }

  // convert the error into something we'd like
  return Promise.reject(
    new Error(error.response.data?.error?.message || error.message)
  )
})

const Root = () => {
  const [user, setUser] = useState()
  const authContextValue = useMemo(() => ({ user, setUser }), [user, setUser])

  useEffect(() => {
    // init services
    const init = async () => {
      await GoogleService.initialize()
    }

    init()
  }, [])

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthContext.Provider value={authContextValue}>
            <ThemeProvider theme={theme}>
              <Toaster />
              <App />
            </ThemeProvider>
          </AuthContext.Provider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<Root />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
