/* eslint-disable import/prefer-default-export */

/**
 * @file BookingV2 constants file
 * @author Alwyn Tan
 */

export const BOOKING_V2_STATUS = {
  PENDING: 'pending',
  IN_PROGRESS: 'in-progress',
  CONFIRMED: 'confirmed',
  CANCELED: 'canceled',
}
