/**
 * @file Drop Details Menu
 * @author Alwyn Tan
 */

import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useUpdateDrop from 'src/hooks/query/drop/useUpdateDrop'
import toast from 'react-hot-toast'
import { DarkerGray, Purple } from 'src/constants/colors'
import { DROP_CATEGORY, DROP_STATUS } from 'src/constants/drop'
import useCreateDrop from 'src/hooks/query/drop/useCreateDrop'
import { useForm } from 'react-hook-form'
import DropPreview from '../molecules/DropPreview'
import FormItem from '../molecules/FormItem'
import TextInput from '../molecules/inputs/TextInput'
import TextAreaInput from '../molecules/inputs/TextAreaInput'
import FileInput from '../molecules/inputs/FileInput'
import DateInput from '../molecules/inputs/DateInput'
import AddressInput from '../molecules/inputs/AddressInput'
import TagInput from '../molecules/inputs/TagInput'
import SelectInput from '../molecules/inputs/SelectInput'
import Button from '../atoms/Button'

const Container = styled.div`
  padding: 40px;
  background-color: ${DarkerGray};
  width: 410px;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: auto;
`

const InventoryButton = styled.p`
  color: ${Purple};
  cursor: pointer;
  padding-top: 5px;
`

const DiscoPartnerButton = styled.p`
  color: ${Purple};
  cursor: pointer;
  padding-top: 5px;
`

const Form = styled.form`
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`

const DropDetailsMenu = ({ drop }) => {
  const [, setSearchParams] = useSearchParams()
  const { control, handleSubmit, reset } = useForm()

  const createDropMutation = useCreateDrop()
  const updateDropMutation = useUpdateDrop(drop?.id)

  useEffect(() => {
    if (!drop) reset({})
    else reset({ ...drop })
  }, [drop, reset])

  const categoryOptions = useMemo(
    () => Object.values(DROP_CATEGORY).map(v => ({ label: v, value: v })),
    []
  )

  const statusOptions = useMemo(
    () => Object.values(DROP_STATUS).map(v => ({ label: v, value: v })),
    []
  )

  const onSubmit = async values => {
    const toastID = toast.loading('Loading')

    try {
      if (drop) await updateDropMutation.mutateAsync(values)
      else await createDropMutation.mutateAsync(values)
      toast.success('Success', { id: toastID })
    } catch (e) {
      toast.error('Failed', { id: toastID })
    }
  }

  const handleManageInventoryClick = () => {
    setSearchParams({ selected: drop?.id, showInventory: true })
  }

  const handleDiscoPartnerClick = () => {
    setSearchParams({ selected: drop?.id, showDiscoPartner: true })
  }

  return (
    <Container>
      <h4>Details</h4>
      <InventoryButton className="button2" onClick={handleManageInventoryClick}>
        Manage Inventory
      </InventoryButton>
      <DiscoPartnerButton className="button2" onClick={handleDiscoPartnerClick}>
        Make Disco Partner
      </DiscoPartnerButton>
      <DropPreview
        drop={drop || {}}
        style={{ alignSelf: 'center', margin: '20px 0' }}
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormItem
          title="Title"
          input={
            <TextInput
              name="title"
              defaultValue={drop?.title}
              control={control}
              required
              placeholder="Enter Title"
            />
          }
        />
        <FormItem
          title="Details"
          input={
            <TextAreaInput
              name="description"
              defaultValue={drop?.description}
              control={control}
              required
              placeholder="Enter Description"
            />
          }
        />
        <FormItem
          title="Video"
          input={<FileInput name="video" control={control} required />}
        />
        <FormItem
          title="Active Date"
          input={
            <DateInput
              placeholder="Select Date"
              name="activeDate"
              defaultValue={drop?.activeDate}
              control={control}
            />
          }
        />
        <FormItem
          title="Expiry Date"
          input={
            <DateInput
              placeholder="Select Date"
              name="expiryDate"
              defaultValue={drop?.expiryDate}
              control={control}
            />
          }
        />
        <FormItem
          title="Neighborhood"
          input={
            <TextInput
              name="location"
              defaultValue={drop?.location}
              control={control}
              placeholder="Enter a Neighborhood"
              required
            />
          }
        />
        <FormItem
          title="Address"
          input={
            <AddressInput
              name="locationV2"
              defaultValue={drop?.locationV2?.formatted}
              control={control}
              placeholder="Enter an address"
              required
            />
          }
        />
        <FormItem
          title="Categories"
          input={
            <TagInput
              name="categories"
              defaultValue={drop?.categories}
              control={control}
              options={categoryOptions}
              placeholder="Choose category"
              required
            />
          }
        />
        <FormItem
          title="Website"
          input={
            <TextInput
              name="link"
              defaultValue={drop?.link}
              control={control}
              placeholder="Enter a link"
            />
          }
        />
        <FormItem
          title="Status"
          input={
            <SelectInput
              name="status"
              options={statusOptions}
              defaultValue={drop?.status || DROP_STATUS.DRAFT}
              control={control}
              placeholder="Choose status"
              required
            />
          }
        />
        <Button
          disabled={
            createDropMutation.isLoading || updateDropMutation.isLoading
          }
        >
          Submit
        </Button>
      </Form>
    </Container>
  )
}

DropDetailsMenu.propTypes = {
  drop: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    activeDate: PropTypes.string,
    expiryDate: PropTypes.string,
    location: PropTypes.string,
    locationV2: PropTypes.shape({
      formatted: PropTypes.string,
    }),
    categories: PropTypes.arrayOf(PropTypes.string),
    link: PropTypes.string,
    status: PropTypes.oneOf(Object.values(DROP_STATUS)),
    defaultPrice: PropTypes.number,
    inventoryDescription: PropTypes.string,
    isBookable: PropTypes.bool,
  }),
}

export default DropDetailsMenu
