/**
 * @file Passes Table
 * @author Alwyn Tan
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import useGetPasses from 'src/hooks/query/pass/useGetPasses'
import { STATUS as PASS_STATUS } from 'src/constants/pass'
import { AnimatePresence } from 'framer-motion'
import PassConfirmModal from 'src/components/compound/Pass/PassConfirmModal'
import Table from '../molecules/Table'

const Container = styled.div``

const EndOfListText = styled.p`
  padding-left: 10px;
  padding-top: 20px;
`

const PassesTable = () => {
  const infiniteTriggerRef = useRef(null)
  const [activePass, setActivePass] = useState('')
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useGetPasses(
    PASS_STATUS.PENDING
  )
  const [, setSearchParams] = useSearchParams()

  const passes = data || []

  const handlePassSelect = useCallback(
    pass => {
      setActivePass(pass)
      setSearchParams({ selected: pass.id })
    },
    [setActivePass, setSearchParams]
  )

  useEffect(() => {
    if (hasNextPage && infiniteTriggerRef.current && !isFetchingNextPage) {
      const observer = new IntersectionObserver(
        entries =>
          entries.forEach(entry => entry.isIntersecting && fetchNextPage()),
        { rootMargin: '0px', threshold: 1.0 }
      )

      const el = infiniteTriggerRef.current

      observer.observe(el)
      return () => observer.unobserve(el)
    }
    return () => {}
  }, [hasNextPage, fetchNextPage, isFetchingNextPage])

  const columns = useMemo(
    () => [
      {
        Header: 'Poster',
        accessor: 'poster',
        // eslint-disable-next-line
        Cell: ({ cell: { value } }) => (
          // eslint-disable-next-line
          <img src={value} width={25} height={25} />
        ),
      },
      {
        Header: 'User ID',
        accessor: 'user.id',
      },
      {
        Header: `User's Name`,
        accessor: 'user.name',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Expiry Date',
        accessor: v =>
          v.expiryDate &&
          dayjs(v.expiryDate).tz().format('DD MMM YYYY h:mma z'),
      },
      {
        Header: 'Active Date',
        accessor: v =>
          v.activeDate &&
          dayjs(v.activeDate).tz().format('DD MMM YYYY h:mma z'),
      },
    ],
    []
  )

  return (
    <Container>
      <Table
        style={{ minWidth: 1200 }}
        columns={columns}
        data={passes}
        activeID={activePass.id}
        onSelect={handlePassSelect}
      />
      <AnimatePresence>
        {activePass && <PassConfirmModal pass={activePass} />}
      </AnimatePresence>
      {!hasNextPage && (
        <EndOfListText>That's it! Total: {data?.length} rows</EndOfListText>
      )}
      <div ref={infiniteTriggerRef} />
    </Container>
  )
}

export default PassesTable
