/**
 * @file Update a given scheduled notification
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from 'react-query'
import { notificationKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const updateScheduledNotification = async (id, fields) =>
  api.put(`notification/schedule/${id}`, { json: fields }).json()

const useUpdateScheduledNotification = id => {
  const queryClient = useQueryClient()

  return useMutation(async fields => updateScheduledNotification(id, fields), {
    onSuccess: () => {
      queryClient.invalidateQueries(notificationKeys.lists())
    },
  })
}

export default useUpdateScheduledNotification
