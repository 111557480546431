/**
 * @file Create STL inventory hook
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from 'react-query'
import { stlInventoryKeys, stlKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const createSTLInventory = async (stlID, fields) =>
  api.post('stl-inventory', { json: { stlID, ...fields } }).json()

const useCreateSTLInventory = stlID => {
  const queryClient = useQueryClient()

  return useMutation(async fields => createSTLInventory(stlID, fields), {
    onSuccess: () => {
      queryClient.invalidateQueries(stlKeys.list())
      queryClient.invalidateQueries(stlInventoryKeys.list(stlID))
    },
  })
}

export default useCreateSTLInventory
