/**
 * @file Number Input Component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useController } from 'react-hook-form'
import { Red } from 'src/constants/colors'
import Spinner from 'src/componentsV2/atoms/Spinner'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`

const LargeContainer = styled(Container)`
  > div:first-child {
    height: 62px;
  }

  input {
    font-weight: 600;
    font-size: 1.333rem;
  }

  span {
    font-weight: 600;
    font-size: 14px;
  }
`

const RegularContainer = styled(Container)`
  > div:first-child {
    height: 50px;
  }

  input {
    font-weight: 400;
    font-size: 1rem;
  }

  span {
    font-weight: 400;
    font-size: 12px;
  }
`

const InputContainer = styled.div`
  border-radius: 8px;
  background-color: #ffffff1a;
  max-width: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
  border: 4px solid ${Red};
  border-color: ${({ error }) => (error ? Red : 'transparent')};
  border-radius: ${({ error }) => (error ? '8px 8px 0 0' : '8px')};

  > input {
    height: 100%;
    width: 100%;
    outline: none;
    border: 0;
    color: white;
    background-color: transparent;
    font-family: Inter;
    font-style: normal;
  }
`

const Error = styled.div`
  background-color: ${Red};
  padding: 5px 15px 10px 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
`

const NumberInput = ({
  size = 'regular',
  disabled = false,
  loading = false,
  required = false,
  inputProps = {},
  defaultValue,
  placeholder = '',
  control,
  name,
  numberType = 'default',
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
    rules: { required },
  })

  const transform = {
    input: v => {
      if (numberType === 'price' && v !== null) return (v || 0) / 100
      return v
    },
    output: v => {
      if (numberType === 'price' && v !== null) return (v || 0) * 100
      return v
    },
  }

  const renderInnerContent = () => (
    <>
      <InputContainer disabled={disabled} error={fieldState.error}>
        <input
          {...inputProps}
          placeholder={placeholder}
          type="number"
          disabled={disabled || loading}
          onWheel={e => {
            // forcefully disallow scrolling...
            e.target.blur()
            setTimeout(() => e.target.focus())
          }}
          {...field}
          onChange={e => field.onChange(transform.output(e.target.value))}
          value={transform.input(field.value)}
        />
        {loading && <Spinner />}
      </InputContainer>
      {fieldState.error && (
        <Error>
          <span>{fieldState.error.message}</span>
        </Error>
      )}
    </>
  )

  switch (size) {
    case 'regular':
      return <RegularContainer>{renderInnerContent()}</RegularContainer>

    case 'large':
    default:
      return <LargeContainer>{renderInnerContent()}</LargeContainer>
  }
}

NumberInput.propTypes = {
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['regular', 'large']),
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  inputProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  numberType: PropTypes.oneOf(['default', 'price']),
}

export default NumberInput
