/**
 * @file Creates an Event
 * @author Jordan Stipp
 */

import { serialize } from 'object-to-formdata'
import { useMutation, useQueryClient } from 'react-query'
import { eventKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const createEvent = async fields =>
  api
    .post('event', {
      body: serialize(fields, { indices: true }),
      timeout: 60000,
      prefixUrl: process.env.REACT_APP_API_URL,
    })
    .json()

const useCreateEvent = () => {
  const queryClient = useQueryClient()

  return useMutation(async fields => createEvent(fields), {
    onSuccess: ({ event }) => {
      queryClient.invalidateQueries(eventKeys.lists())
      return { event }
    },
  })
}

export default useCreateEvent
