/**
 * @file Accepts a waitlisted user
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from 'react-query'
import { userKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const acceptWaitlistedUser = async id =>
  api.post('user/accept-from-waitlist', { json: { userIDs: [id] } }).json()

const useAcceptWaitlistedUser = () => {
  const queryClient = useQueryClient()

  return useMutation(async id => acceptWaitlistedUser(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.waitlistedLists())
    },
  })
}

export default useAcceptWaitlistedUser
