/* eslint-disable import/prefer-default-export */

// TODO: this is so so so hacky
// TODO: fix this shit asap after launch
export const KNOWN_PARTNERS = (() => {
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    return {
      '6417620e94890bbe149ba6ed': 'Disco Store',
      '6418c1022b97e5091ff41667': '260 Sample Sale',
    }
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return {
      '64192ae75982c700145ea2c6': '260 Sample Sale',
      // '64192b075982c700145ea2c8': 'Eclipse',
    }
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return {
      '6422d265b946ac001441b305': '260 Sample Sale',
      // '6422d277b946ac001441b307': 'Eclipse',
    }
  }

  return {}
})()
