/**
 * @file Query key generator
 * @author Jordan Stipp
 */

export const dropKeys = {
  all: ['drop'],
  lists: () => [...dropKeys.all, 'list'],
  list: status => [...dropKeys.lists(), status],
  details: () => [...dropKeys.all, 'detail'],
  detail: id => [...dropKeys.details(), id],
}

export const orderKeys = {
  all: ['order'],
  lists: () => [...orderKeys.all, 'list'],
  list: status => [...orderKeys.lists(), status],
  details: () => [...orderKeys.all, 'detail'],
  detail: id => [...orderKeys.details(), id],
}

export const bookingV2Keys = {
  all: ['bookingV2'],
  lists: () => [...bookingV2Keys.all, 'list'],
  list: status => [...bookingV2Keys.lists(), status],
  details: () => [...bookingV2Keys.all, 'detail'],
  detail: id => [...bookingV2Keys.details(), id],
}

export const inventoryKeys = {
  all: ['inventory'],
  lists: () => [...inventoryKeys.all, 'list'],
  list: ({ dropID, partySize, dateRange } = {}) => [
    ...inventoryKeys.lists(),
    { dropID, partySize, dateRange },
  ],
  details: () => [...inventoryKeys.all, 'detail'],
  detail: id => [...inventoryKeys.details(), id],
}

export const inventoryV2Keys = {
  all: ['inventoryV2'],
  lists: () => [...inventoryV2Keys.all, 'list'],
  list: ({ dropID } = {}) => [...inventoryV2Keys.lists(), { dropID }],
}

export const waitlistCodeKeys = {
  all: ['waitlistCode'],
  lists: () => [...waitlistCodeKeys.all, 'list'],
  list: status => [...waitlistCodeKeys.lists(), status],
}

export const eventKeys = {
  all: ['event'],
  lists: () => [...eventKeys.all, 'list'],
  list: status => [...eventKeys.lists(), status],
  eventSeriesInstanceLists: () => [...eventKeys.all, 'eventSeriesInstances'],
  eventSeriesInstanceList: eventSeriesID => [
    ...eventKeys.eventSeriesInstanceLists(),
    eventSeriesID,
  ],
  details: () => [...eventKeys.all, 'detail'],
  detail: id => [...eventKeys.details(), id],
  attendees: id => [...eventKeys.detail(id), 'attendees'],
}

export const notificationKeys = {
  all: ['notification'],
  lists: () => [...notificationKeys.all, 'list'],
  list: query => [...notificationKeys.lists(), query],
  details: () => [...notificationKeys.all, 'detail'],
  detail: id => [...notificationKeys.details(), id],
}

export const stlKeys = {
  all: ['stl'],
  lists: () => [...stlKeys.all, 'list'],
  list: query => [...stlKeys.lists(), query],
  details: () => [...stlKeys.all, 'detail'],
  detail: id => [...stlKeys.details(), id],
}

export const stlInventoryKeys = {
  all: ['stlInventory'],
  lists: () => [...stlInventoryKeys.all, 'list'],
  list: stlID => [...stlInventoryKeys.lists(), stlID],
  details: () => [...stlInventoryKeys.all, 'detail'],
  detail: id => [...stlInventoryKeys.details(), id],
}

export const userKeys = {
  all: ['user'],
  lists: () => [...userKeys.all, 'list'],
  waitlistedLists: () => [...userKeys.lists(), 'waitlisted'],
  waitlistedList: query => [...userKeys.waitlistedLists(), query],
}

export const passKeys = {
  all: ['pass'],
  lists: () => [...passKeys.all, 'list'],
  list: status => [...passKeys.lists(), status],
}
