/**
 * @file Gets a bookingV2
 * @author Alwyn Tan
 */

import { useQuery } from 'react-query'
import axios from 'axios'
import { bookingV2Keys } from 'src/query-key-factory'

const getBookingV2 = async (id, params = {}) =>
  (
    await axios.get(`/order/v2/booking/${id}`, {
      params: { ...params, showAllUsers: true },
    })
  ).data

const useGetBookingV2 = (id, params) =>
  useQuery(bookingV2Keys.detail(id), () => getBookingV2(id, params), {
    select: data => data?.bookingV2,
    enabled: !!id,
  })

export default useGetBookingV2
