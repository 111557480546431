/**
 * @file Simple search input UI
 * @author Alwyn Tan
 */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DarkGray } from 'src/constants/colors'
import { Search } from 'react-feather'
import { debounce } from 'lodash'

const Container = styled.div`
  height: 40px;
  width: 335px;
  border-radius: 20px;
  background-color: ${DarkGray};
  display: flex;
  align-items: center;
  padding-left: 14px;

  > input {
    color: white;
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: 0;
    padding-left: 8px;
  }
`

const SearchInput = ({
  shouldDebounce = true,
  onChange = () => {},
  placeholder = 'Enter Text',
}) => {
  const handleOnChange = useMemo(() => {
    if (shouldDebounce) return debounce(onChange, 300)
    return onChange()
  }, [shouldDebounce, onChange])

  return (
    <Container>
      <Search size={16} style={{ opacity: 0.5 }} />
      <input type="text" placeholder={placeholder} onChange={handleOnChange} />
    </Container>
  )
}

SearchInput.propTypes = {
  shouldDebounce: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

export default SearchInput
