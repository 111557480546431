/**
 * @file Get STLs query
 * @author Alwyn Tan
 */

import { useInfiniteQuery } from 'react-query'
import { stlKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const getSTLs = async (status, pageParam) =>
  api.get('stl', { searchParams: { showAll: true, ...pageParam } }).json()

const useGetSTLs = status =>
  useInfiniteQuery(
    stlKeys.list(),
    async ({ pageParam }) => getSTLs(status, pageParam),
    {
      select: data =>
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.stls], []),
      getNextPageParam: prevPage => {
        if (prevPage.canLoadMore) {
          const prevSTL = prevPage.stls[(prevPage?.stls.length || 0) - 1]

          const pageParams = {}

          if (prevSTL?.id) pageParams.prevID = prevSTL.id
          if (prevSTL?.nextAvailableStartDate)
            pageParams.prevNextAvailableStartDate =
              prevSTL.nextAvailableStartDate

          return pageParams
        }
        return undefined
      },
    }
  )

export default useGetSTLs
