/**
 * @file Form Item
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '../atoms/Text'

const Container = styled.div`
  flex: 1;
`

const FormItem = ({ title = '', input, style = {} }) => (
  <Container style={style}>
    {title && (
      <Text type="button2" style={{ paddingBottom: 6 }}>
        {title}
      </Text>
    )}
    {input}
  </Container>
)

FormItem.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  input: PropTypes.node.isRequired,
}

export default FormItem
