/**
 * @file Waitlisted Users Table
 * @author Alwyn Tan
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import useGetWaitlistedUsers from 'src/hooks/query/user/useGetWaitlistedUsers'
import { AnimatePresence } from 'framer-motion'
import WaitlistUserModal from 'src/components/compound/Waitlist/WaitlistUserModal'
import SearchInput from '../atoms/SearchInput'
import Table from '../molecules/Table'

const Container = styled.div``

const EndOfListText = styled.p`
  padding-left: 10px;
  padding-top: 20px;
`

const WaitlistedUserTable = () => {
  const infiniteTriggerRef = useRef(null)
  const [activeUser, setActiveUser] = useState(null)
  const [, setSearchParams] = useSearchParams()
  const [searchQuery, setSearchQuery] = useState('')

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useGetWaitlistedUsers(searchQuery)

  const users = data || []

  const handleSearchChange = e => {
    setSearchQuery(e.target.value)
  }

  const handleUserSelect = useCallback(
    user => {
      setActiveUser(user)
      setSearchParams({ selected: user.id })
    },
    [setSearchParams]
  )

  useEffect(() => {
    if (hasNextPage && infiniteTriggerRef.current && !isFetchingNextPage) {
      const observer = new IntersectionObserver(
        entries =>
          entries.forEach(entry => entry.isIntersecting && fetchNextPage()),
        { rootMargin: '0px', threshold: 1.0 }
      )

      const el = infiniteTriggerRef.current

      observer.observe(el)
      return () => observer.unobserve(el)
    }
    return () => {}
  }, [hasNextPage, fetchNextPage, isFetchingNextPage])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Username',
        accessor: v => v.username && `@${v.username}`,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Created At',
        accessor: v =>
          v.createdAt && dayjs(v.createdAt).tz().format('DD MMM YYYY h:mma z'),
      },
    ],
    []
  )

  return (
    <Container>
      <SearchInput placeholder="Search user" onChange={handleSearchChange} />
      <Table
        style={{ minWidth: 1200 }}
        columns={columns}
        data={users}
        activeID={activeUser?.id}
        onSelect={handleUserSelect}
      />
      <AnimatePresence>
        {activeUser && <WaitlistUserModal user={activeUser} />}
      </AnimatePresence>
      {!hasNextPage && (
        <EndOfListText>That's it! Total: {data?.length} rows</EndOfListText>
      )}
      <div ref={infiniteTriggerRef} />
    </Container>
  )
}

export default WaitlistedUserTable
