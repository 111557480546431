/**
 * @file Promo Notification Menu
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import { DarkerGray } from 'src/constants/colors'
import useUpdateScheduledNotification from 'src/hooks/query/notification/useUpdateScheduledNotification'
import useCreateScheduledNotification from 'src/hooks/query/notification/useCreateScheduledNotification'
import FormItem from '../molecules/FormItem'
import TextInput from '../molecules/inputs/TextInput'
import Button from '../atoms/Button'
import DateInput from '../molecules/inputs/DateInput'
import TextAreaInput from '../molecules/inputs/TextAreaInput'

const Container = styled.div`
  padding: 40px;
  background-color: ${DarkerGray};
  width: 410px;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: auto;
`

const Form = styled.form`
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`

const PromoNotificationMenu = ({ notificationJob }) => {
  const { control, handleSubmit, reset } = useForm()

  const createScheduledNotificationMutation = useCreateScheduledNotification()
  const updateScheduledNotificationMutation = useUpdateScheduledNotification(
    notificationJob?.id
  )

  useEffect(() => {
    if (!notificationJob) reset({})
    else reset({ ...notificationJob })
  }, [notificationJob, reset])

  const onSubmit = async values => {
    const toastID = toast.loading('Loading')

    try {
      if (notificationJob)
        await updateScheduledNotificationMutation.mutateAsync(values)
      else await createScheduledNotificationMutation.mutateAsync(values)
      toast.success('Success', { id: toastID })
    } catch (e) {
      toast.error('Failed', { id: toastID })
    }
  }

  return (
    <Container>
      <h4 style={{ marginBottom: 20 }}>Manage Notification</h4>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormItem
          title="Scheduled For"
          input={
            <DateInput
              name="date"
              defaultValue={notificationJob?.nextRunAt}
              control={control}
              required
              placeholder="Select Date"
            />
          }
        />
        <FormItem
          title="Title"
          input={
            <TextInput
              name="title"
              defaultValue={notificationJob?.data?.title}
              control={control}
              placeholder="Enter Title"
            />
          }
        />
        <FormItem
          title="Message*"
          input={
            <TextAreaInput
              name="message"
              defaultValue={notificationJob?.data?.message}
              control={control}
              required
              placeholder="Enter Message"
            />
          }
        />
        <FormItem
          title="Action Target Type"
          input={
            <TextInput
              name="action.target"
              defaultValue="EVENT"
              control={control}
              disabled
            />
          }
        />
        <FormItem
          title="Action Target ID"
          input={
            <TextInput
              name="action.targetID"
              defaultValue={notificationJob?.data?.action?.targetID}
              control={control}
              placeholder="Enter Event Target"
            />
          }
        />
        <Button>Submit</Button>
      </Form>
    </Container>
  )
}

PromoNotificationMenu.propTypes = {
  notificationJob: PropTypes.shape({
    id: PropTypes.string,
    nextRunAt: PropTypes.string,
    data: PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
      action: PropTypes.shape({
        target: PropTypes.string,
        targetID: PropTypes.string,
      }),
    }),
  }),
}

export default PromoNotificationMenu
