/* eslint-disable import/prefer-default-export */

/**
 * @file Pass constants
 * @author Alwyn Tan
 */

export const STATUS = {
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  FAILED: 'FAILED', // failed payments
  CANCELED: 'CANCELED',
  PENDING: 'PENDING', // pending confirmation
  CONFIRMED: 'CONFIRMED',
}
