/**
 * @file Get Event Phone Numbers
 * @author Jordan Stipp
 */

import { useMutation } from 'react-query'
import api from 'src/utils/api'

const getPhoneNumbers = async id => {
  const searchParams = { prop: 'phoneNumber' }

  return api.get(`event/attendees-props/${id}`, { searchParams }).json()
}

const useEventPhoneNumbers = () => useMutation(id => getPhoneNumbers(id))

export default useEventPhoneNumbers
