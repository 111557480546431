/**
 * @file Address input
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import { useController } from 'react-hook-form'
import { DarkerGray, DarkGray, Red } from 'src/constants/colors'
import GoogleService from 'src/services/google'
import styled from 'styled-components'

const customStyles = {
  container: provided => ({
    ...provided,
  }),
  control: provided => ({
    ...provided,
    backgroundColor: '#ffffff1a',
    border: 0,
    minHeight: 50,
    padding: '7px 0 7px 10px',
    width: '100%',
    borderRadius: 8,
    boxShadow: 'none',
  }),
  input: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    opacity: 0.4,
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    padding: 15,
    backgroundColor: state.isFocused ? DarkerGray : 'transparent',

    ':active': {
      ...provided[':active'],
      backgroundColor: state.isFocused ? DarkerGray : 'transparent',
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: DarkerGray,
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: 'white',
    fontStyle: 'normal',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '0.8rem',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: DarkGray,
  }),
  menuList: provided => ({
    ...provided,
    borderRadius: 8,
  }),
}

const Container = styled.div`
  position: relative;
`

const ErrorBoundary = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  border: 4px solid ${Red};
  border-radius: 8px 8px 0 0;
  pointer-events: none;
  z-index: 1;
`

const Error = styled.div`
  background-color: ${Red};
  padding: 5px 15px 10px 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
`

const AddressInput = ({
  name,
  control,
  defaultValue,
  required = false,
  placeholder = '',
}) => {
  const [sessionToken, setSessionToken] = useState(null)

  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
    rules: { required },
  })

  const loadOptions = async (input, callback) => {
    if (!sessionToken)
      setSessionToken(GoogleService.getAutocompleteSessionToken())
    const response = await GoogleService.getPlacePredictions({
      input,
      sessionToken,
    })
    const options = response.predictions.map(prediction => ({
      value: prediction,
      label: prediction.description,
    }))

    callback(options)
  }

  const transform = {
    output: async value => {
      const place = await GoogleService.getPlaceDetails(null, {
        placeId: value.place_id,
        sessionToken,
      })
      setSessionToken(null)

      const address = GoogleService.parseAddressComponents(
        place.address_components
      )

      return {
        name: place.name,
        utcOffsetMinutes: place.utc_offset_minutes,
        formatted: place.formatted_address,
        geo: {
          type: 'Point',
          coordinates: [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ],
        },
        ...address,
        placeID: place.place_id,
      }
    },
  }

  return (
    <>
      <Container>
        {fieldState.error && <ErrorBoundary />}
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          styles={customStyles}
          placeholder={placeholder}
          onChange={async v => field.onChange(await transform.output(v.value))}
          value={
            field.value ? { value: field.value, label: field.value?.name } : ''
          }
          onBlur={field.onBlur}
          name={field.name}
          ref={field.ref}
        />
      </Container>
      {fieldState.error && (
        <Error>
          <span>{fieldState.error.message}</span>
        </Error>
      )}
    </>
  )
}

AddressInput.propTypes = {
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default AddressInput
