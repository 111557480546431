/**
 * @file Get Events query
 * @author Jordan Stipp
 */

import { useInfiniteQuery } from 'react-query'
import { eventKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const getEventAttendees = async (id, prevItem) => {
  const searchParams = { ...prevItem }
  return api.get(`event/attendees/${id}`, { searchParams }).json()
}

const useGetEventAttendees = id =>
  useInfiniteQuery(
    eventKeys.attendees(id),
    async ({ pageParam }) => getEventAttendees(id, pageParam),
    {
      select: data =>
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.attendees], []),
      getNextPageParam: prevPage => {
        if (prevPage?.canLoadMore) {
          return {
            prevUserID:
              // eslint-disable-next-line no-underscore-dangle
              prevPage?.attendees[(prevPage?.attendees.length || 0) - 1]?._id,
          }
        }
        return undefined
      },
    }
  )
export default useGetEventAttendees
