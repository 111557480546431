/**
 * @file Ky api helper
 * @author Alwyn Tan
 */

import ky from 'ky'

let accessToken = null

const api = ky.extend({
  prefixUrl: '/api',
  retry: {
    limit: 2,
    methods: ['get', 'put', 'head', 'delete', 'options', 'trace'],
    statusCodes: [401, 403, 408, 413, 429, 500, 502, 503, 504],
  },
  hooks: {
    beforeRequest: [
      async request => {
        const hasRefreshToken = document.cookie.indexOf('rtExists=') !== -1
        const isLoginRequest =
          request.url.endsWith('/api/user/silent-login') ||
          request.url.endsWith('/api/user/login')

        if (!accessToken && hasRefreshToken && !isLoginRequest) {
          const resp = await ky
            .post('/api/user/silent-login', {
              credentials: 'include',
              throwHttpErrors: false,
            })
            .json()
          accessToken = resp?.accessToken
        }

        if (accessToken)
          request.headers.set('Authorization', `Bearer ${accessToken}`)
      },
    ],
    afterResponse: [
      (request, options, response) => {
        if (response.status === 403 || response.status === 401)
          accessToken = null
      },
    ],
  },
})

export default api
