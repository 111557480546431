/**
 * @file Creates a drop
 * @author Alwyn Tan
 */

import { serialize } from 'object-to-formdata'
import { useMutation, useQueryClient } from 'react-query'
import { dropKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const createDrop = async fields =>
  api
    .post('drop', {
      body: serialize(fields, { indices: true }),
      timeout: 60000,
      prefixUrl: process.env.REACT_APP_API_URL,
    })
    .json()

const useCreateDrop = () => {
  const queryClient = useQueryClient()

  return useMutation(async fields => createDrop(fields), {
    onSuccess: ({ drop }) => {
      queryClient.invalidateQueries(dropKeys.list(drop.status))
    },
  })
}

export default useCreateDrop
