/**
 * @file Updates an Event
 * @author Jordan Stipp
 */

import { serialize } from 'object-to-formdata'
import { useMutation, useQueryClient } from 'react-query'
import { eventKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const updateEvent = async (id, fields) =>
  api
    .put(`event/${id}`, {
      body: serialize(fields, { indices: true }),
      timeout: 60000,
      prefixUrl: process.env.REACT_APP_API_URL,
    })
    .json()

const useUpdateEvent = id => {
  const queryClient = useQueryClient()

  return useMutation(async fields => updateEvent(id, fields), {
    onSuccess: () => {
      queryClient.invalidateQueries(eventKeys.detail(id))
    },
  })
}

export default useUpdateEvent
