/**
 * @file Inventory Menu
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DarkerGray, Purple } from 'src/constants/colors'
import useGetDrop from 'src/hooks/query/drop/useGetDrop'
import useGetInventories from 'src/hooks/query/inventoryV2/useGetInventoryV2s'
import InventoryV2Card from './Drop/InventoryV2Card'
import CreateInventoryModal from './Drop/CreateInventoryModal'

const Container = styled.div`
  padding: 40px;
  background-color: ${DarkerGray};
  width: 410px;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: auto;
`

const AddButton = styled.p`
  cursor: pointer;
`

const InventoryMenu = ({ dropID }) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const { data: dropData } = useGetDrop(dropID)
  const { data: inventories } = useGetInventories(dropID)
  const drop = dropData?.drop

  const renderInventories = () =>
    inventories?.map(inventory => <InventoryV2Card inventory={inventory} />)

  return (
    <Container>
      <h4>{drop?.title} inventory</h4>
      <AddButton
        className="button2"
        style={{ color: Purple, margin: '10px 0 20px 0' }}
        onClick={() => setShowCreateModal(true)}
      >
        + Add
      </AddButton>
      {showCreateModal && (
        <CreateInventoryModal
          drop={drop}
          onClose={() => setShowCreateModal(false)}
        />
      )}
      {renderInventories()}
    </Container>
  )
}

InventoryMenu.propTypes = {
  dropID: PropTypes.string.isRequired,
}

export default InventoryMenu
