/**
 * @file To be used mostly for inventory, to be reworked in the future
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import { useController } from 'react-hook-form'
import { Red } from 'src/constants/colors'

const Container = styled.div`
  width: 100%;
  background-color: ${({ disabled }) =>
    disabled ? 'transparent' : '#ffffff1a'};
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 4px solid ${Red};
  border-color: ${({ error }) => (error ? Red : 'transparent')};
  border-radius: ${({ error }) => (error ? '8px 8px 0 0' : '8px')};

  .react-datepicker__input-container {
    height: 100%;

    input {
      height: 100%;
      color: white;
      background-color: transparent;
      outline: none;
      border: 0;
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      font-size: 1.2rem;
    }
  }
`

const TinyDateInput = ({
  required = false,
  defaultValue,
  control,
  name,
  placeholder = '',
  disabled = false,
  style = {},
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
    rules: { required },
  })

  const transform = {
    input: v => {
      if (!v) return v

      const offsetDifference = dayjs().tz().utcOffset() - dayjs().utcOffset()
      return dayjs(v).add(offsetDifference, 'minute').toDate()
    },
    output: v => {
      if (!v) return v

      const offsetDifference = dayjs().tz().utcOffset() - dayjs().utcOffset()
      return dayjs(v).subtract(offsetDifference, 'minute').toDate()
    },
  }

  return (
    <Container error={fieldState.error} disabled={disabled} style={style}>
      <DatePicker
        disabled={disabled}
        placeholderText={placeholder}
        showTimeSelect
        dateFormat="h:mm a"
        selected={transform.input(field.value)}
        onChange={e => field.onChange(transform.output(e))}
      />
    </Container>
  )
}

TinyDateInput.propTypes = {
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

export default TinyDateInput
