/**
 * @file Promo Notifications page management code
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import useGetScheduledNotification from 'src/hooks/query/notification/useGetScheduledNotification'
import Button from '../atoms/Button'
import PromoNotificationMenu from '../organisms/PromoNotificationMenu'
import PromoNotificationTable from '../templates/PromoNotificationTable'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  padding: 40px 0px 0px 40px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  padding-right: 40px;
`

const PromoNotification = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentID = searchParams.get('selected')
  const isNew = searchParams.get('new')

  const { data } = useGetScheduledNotification(currentID)
  const notificationJob = data?.job

  const handleAddClick = () => {
    setSearchParams({ new: true })
  }

  return (
    <Container>
      <Content>
        <Header>
          <Title>
            <h2 style={{ marginRight: 20 }}>Drops</h2>
            <Button size="medium" onClick={handleAddClick}>
              + Add
            </Button>
          </Title>
        </Header>
        <InnerContainer>
          <PromoNotificationTable />
        </InnerContainer>
      </Content>
      {currentID && notificationJob && (
        <PromoNotificationMenu notificationJob={notificationJob} />
      )}
      {isNew && <PromoNotificationMenu />}
    </Container>
  )
}

export default PromoNotification
