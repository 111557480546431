/**
 * @file Order Tabs that house the table logic
 * @author Alwyn Tan
 */

import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import useGetEvents from 'src/hooks/query/event/useGetEvents'
import dayjs from 'dayjs'
import Table from 'src/componentsV2/molecules/Table'
import EventModal from './EventModal'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  padding-bottom: 40px;
`

const EventTab = ({ status }) => {
  const infiniteTriggerRef = useRef(null)
  const [activeEvent, setActiveEvent] = useState({})
  const [showModal, setShowModal] = useState(false)
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useGetEvents(status)
  const events = data || []

  useEffect(() => {
    if (hasNextPage && infiniteTriggerRef.current && !isFetchingNextPage) {
      const observer = new IntersectionObserver(
        entries =>
          entries.forEach(entry => entry.isIntersecting && fetchNextPage()),
        { rootMargin: '0px', threshold: 1.0 }
      )

      const el = infiniteTriggerRef.current

      observer.observe(el)
      return () => observer.unobserve(el)
    }
    return () => {}
  }, [hasNextPage, fetchNextPage, isFetchingNextPage])

  const handleEventSelect = useCallback(
    event => {
      setActiveEvent(event)
      setShowModal(true)
    },
    [setActiveEvent]
  )

  const columns = useMemo(
    () => [
      { Header: 'Title', accessor: 'title' },
      {
        Header: 'Next Available Start Date',
        accessor: v =>
          v.nextAvailableStartDate &&
          dayjs(v.nextAvailableStartDate).tz().format('DD MMM YYYY h:mma z'),
      },
      {
        Header: 'Next Available End Date',
        accessor: v =>
          v.nextAvailableEndDate &&
          dayjs(v.nextAvailableEndDate).tz().format('DD MMM YYYY h:mma z'),
      },
      {
        Header: 'Start Date',
        accessor: v =>
          v.startDate && dayjs(v.startDate).tz().format('DD MMM YYYY h:mma z'),
      },
      {
        Header: 'End Date',
        accessor: v =>
          v.endDate && dayjs(v.endDate).tz().format('DD MMM YYYY h:mma z'),
      },
      {
        Header: 'EventType',
        accessor: 'isSeries',
        // eslint-disable-next-line
        Cell: ({ cell: { value } }) =>
          value ? <p>EventSeries</p> : <p>Non-Recurring Event</p>,
      },
    ],
    []
  )

  return (
    <Container>
      <Table
        columns={columns}
        data={events}
        activeID={activeEvent?.id}
        onSelect={handleEventSelect}
      />
      <AnimatePresence>
        {showModal && <EventModal activeEvent={activeEvent} />}
      </AnimatePresence>
      <div ref={infiniteTriggerRef} />
    </Container>
  )
}

EventTab.propTypes = {
  status: PropTypes.string,
}

export default EventTab
