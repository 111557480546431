/* eslint-disable import/prefer-default-export */

/**
 * @file Drop related constants
 * @author Alwyn Tan
 */

export const DROP_CATEGORY = {
  SHOPPING: '🛍 Shopping',
  BREAKFAST: '🍳 Breakfast',
  GETAWAY: '🏝 Getaway',
  AT_HOME: '🛋 At Home',
  DATING: '💞 Dating',
  BALL_OUT: '🤑 Ball Out',
  ENTERTAINMENT: '🎭 Entertainment',
  ART_CULTURE: '🎨 Art & Culture',
  DINING: '🍽 Dining',
  COFFEE: '☕️ Coffee',
  BIRTHDAY: '🎂 Birthday',
  SELFCARE: '🛁 Selfcare',
}

export const DROP_STATUS = {
  ACTIVE: 'active',
  DRAFT: 'draft',
}

export const DEAL_TYPES = {
  FREE_DRINK: 'freedrink',
  FREE_DESSERT: 'freedessert',
}
