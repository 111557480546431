/**
 * @file Event Screen
 * @author Jordan Stipp
 */

import React from 'react'
import styled from 'styled-components'
import { NavLink, Outlet, useParams, useSearchParams } from 'react-router-dom'
import useGetEvent from 'src/hooks/query/event/useGetEvent'
import Button from '../atoms/Button'
import EventDetailsMenu from '../organisms/EventDetailsMenu'
import EventSeriesInstanceMenu from '../organisms/EventSeriesInstanceMenu'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
  flex: 1;
  width: 100%;
  padding: 40px 0px 0px 40px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`

const Nav = styled.nav`
  display: flex;
  margin-top: 30px;

  > * {
    margin-right: 30px;
    color: white;
    text-decoration: none;
    opacity: 0.4;
  }

  .active {
    opacity: 1;
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const Event = () => {
  const params = useParams()
  const { data } = useGetEvent(params?.id)
  const [searchParams, setSearchParams] = useSearchParams()
  const isNewDrop = searchParams.get('new')
  const showInstancePanel = searchParams.get('showInstancePanel')

  const handleAddClick = () => {
    setSearchParams({ new: true })
  }

  return (
    <Container>
      <Content>
        <Header>
          <Title>
            <h3>Events</h3>
            {!params?.id && (
              <Button size="medium" onClick={handleAddClick}>
                + Add
              </Button>
            )}
          </Title>
        </Header>
        <Nav>
          <NavLink to="upcoming">
            <h4>Upcoming</h4>
          </NavLink>
          <NavLink to="past">
            <h4>Past/Ongoing</h4>
          </NavLink>
        </Nav>
        <InnerContainer>
          <Outlet />
        </InnerContainer>
      </Content>
      {!showInstancePanel && params?.id && data?.event && (
        <EventDetailsMenu event={data?.event} />
      )}
      {isNewDrop && <EventDetailsMenu />}
      {showInstancePanel && (
        <EventSeriesInstanceMenu eventSeries={data?.event} />
      )}
    </Container>
  )
}

export default Event
