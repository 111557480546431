/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DarkerGray } from 'src/constants/colors'
import { useForm } from 'react-hook-form'
import TextInput from 'src/componentsV2/molecules/inputs/TextInput'
import Text from 'src/componentsV2/atoms/Text'
import useCreateInventoryV2 from 'src/hooks/query/inventoryV2/useCreateInventoryV2'
import Button from 'src/componentsV2/atoms/Button'
import toast from 'react-hot-toast'
import NumberInput from 'src/componentsV2/molecules/inputs/NumberInput'
import FormItem from 'src/componentsV2/molecules/FormItem'
import TextAreaInput from 'src/componentsV2/molecules/inputs/TextAreaInput'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Backdrop = styled.div`
  position: absolute;
  z-index: 0;
  background-color: #00000080;
  height: 100%;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`

const Modal = styled.div`
  padding: 24px;
  background-color: ${DarkerGray};
  z-index: 1;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
  user-select: none;
  max-height: 90%;
  overflow-y: auto;
`

const CreateInventoryModal = ({ drop, onClose }) => {
  const { control, handleSubmit, formState } = useForm({ mode: 'onChange' })
  const createInventoryV2Mutation = useCreateInventoryV2(drop.id)

  const onSubmit = async data => {
    const toastID = toast.loading('Creating inventoryV2')

    try {
      await createInventoryV2Mutation.mutateAsync(data)
      toast.success('Created inventoryV2', { id: toastID })
    } catch {
      toast.error('Failed to create inventoryV2', { id: toastID })
    }

    onClose()
  }

  return (
    <Container>
      <Backdrop onClick={onClose} />
      <Modal>
        <Text type="h5" style={{ paddingBottom: 10 }}>
          Create inventory for {drop.title}
        </Text>
        <FormItem
          title="Inventory Title"
          input={
            <TextInput
              control={control}
              name="title"
              placeholder="Enter inventory title"
              required
            />
          }
        />
        <FormItem
          title="Inventory Description"
          input={
            <TextAreaInput
              control={control}
              name="description"
              placeholder="Enter inventory description"
              required
            />
          }
        />
        <Row>
          <FormItem
            title="Price"
            input={
              <NumberInput
                name="price"
                control={control}
                placeholder="Price"
                numberType="price"
                required
              />
            }
          />
          <FormItem
            title="Quantity"
            input={
              <NumberInput
                name="quantity"
                control={control}
                placeholder="Quantity"
                required
              />
            }
          />
        </Row>
        <FormItem
          title="Deal Title"
          input={
            <TextInput
              control={control}
              name="deal.title"
              placeholder="Enter deal title"
            />
          }
        />
        <FormItem
          title="Deal Value"
          input={
            <NumberInput
              control={control}
              name="deal.value"
              placeholder="Enter deal value"
              numberType="price"
            />
          }
        />
        <Button
          size="medium"
          disabled={!formState.isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Create
        </Button>
      </Modal>
    </Container>
  )
}

CreateInventoryModal.propTypes = {
  drop: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  onClose: PropTypes.func,
}

export default CreateInventoryModal
