/**
 * @file App
 * @author Alwyn Tan
 */

import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import useSilentLogin from './hooks/query/user/useSilentLogin'
import AuthContext from './contexts/AuthContext'
import EventTab from './components/compound/Event/EventTab'
import WaitlistCodeTab from './components/compound/WaitlistCode/WaitlistCodeTab'
import { WAITLIST_CODE_STATUS } from './constants/waitlist-code'
import EventDetails from './components/compound/Event/EventDetails'
import LoadingScreen from './componentsV2/pages/LoadingScreen'
import FourOhFour from './componentsV2/pages/404'
import Event from './componentsV2/pages/Event'
import WaitlistCode from './componentsV2/pages/WaitlistCode'
import Auth from './componentsV2/pages/Auth'
import Drop from './componentsV2/pages/Drop'
import DropTable from './componentsV2/templates/DropTable'
import SideMenu from './componentsV2/organisms/SideMenu'
import PromoNotification from './componentsV2/pages/PromoNotification'
import STL from './componentsV2/pages/STL'
import Waitlist from './componentsV2/pages/Waitlist'
import Passes from './componentsV2/pages/Passes'
import BookingV2 from './componentsV2/pages/BookingV2'
import BookingTabV2 from './componentsV2/pages/BookingTabV2'
import { BOOKING_V2_STATUS } from './constants/booking'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`

const MainContent = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
`

const App = () => {
  const [initialized, setInitialized] = useState(false)
  const { user } = useContext(AuthContext)
  const { mutateAsync } = useSilentLogin()

  // note: annoying bug where setcontext takes longer to execute than this return func
  // figure out a way to fix it. causing route refreshes
  useEffect(() => {
    const silentLoginUser = async () => {
      await mutateAsync()
      setInitialized(true)
    }
    silentLoginUser()
  }, [mutateAsync])

  if (!initialized) return <LoadingScreen />

  if (user?.accessToken) {
    return (
      <Container>
        <SideMenu />
        <MainContent>
          <Routes>
            <Route index element={<Navigate to="booking" />} />
            <Route path="404" element={<FourOhFour />} />
            <Route path="drop" element={<Drop />}>
              <Route path="active" element={<DropTable status="active" />} />
              <Route path="draft" element={<DropTable status="draft" />} />
              <Route index element={<Navigate to="active" />} />
            </Route>
            <Route path="event" element={<Event />}>
              <Route path=":id" element={<EventDetails />}>
                <Route path=":id" element={<EventDetails />} />
              </Route>
              <Route path="upcoming" element={<EventTab status="upcoming" />} />
              <Route path="past" element={<EventTab status="past" />} />
              <Route index element={<Navigate to="upcoming" />} />
            </Route>
            <Route path="stl" element={<STL />} />
            <Route path="booking" element={<BookingV2 />}>
              {Object.values(BOOKING_V2_STATUS).map(status => (
                <Route
                  key={`Booking-${status}`}
                  path={status}
                  element={<BookingTabV2 status={status} />}
                />
              ))}
              <Route index element={<Navigate to="pending" />} />
            </Route>
            <Route path="login" element={<Navigate to="/" />} />
            <Route path="waitlist" element={<Waitlist />} />
            <Route path="waitlist-code" element={<WaitlistCode />}>
              <Route
                path={WAITLIST_CODE_STATUS.ACTIVE}
                element={
                  <WaitlistCodeTab
                    status={WAITLIST_CODE_STATUS.ACTIVE}
                    key={`Waitlist-Code-Tab-${WAITLIST_CODE_STATUS.ACTIVE}`}
                  />
                }
              />
              <Route
                path={WAITLIST_CODE_STATUS.INACTIVE}
                element={
                  <WaitlistCodeTab
                    status={WAITLIST_CODE_STATUS.INACTIVE}
                    key={`Waitlist-Code-Tab-${WAITLIST_CODE_STATUS.INACTIVE}`}
                  />
                }
              />
              <Route index element={<Navigate to="active" />} />
            </Route>
            <Route path="notifications" element={<PromoNotification />} />
            <Route path="passes" element={<Passes />} />
            <Route path="*" element={<Navigate to="404" />} />
          </Routes>
        </MainContent>
      </Container>
    )
  }

  return (
    <Routes>
      <Route path="login" element={<Auth />} />
      <Route path="*" element={<Navigate to="login" />} />
    </Routes>
  )
}

export default App
