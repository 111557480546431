/**
 * @file Update Disco Partner Hook
 * @author Jordan Stipp
 */

import { useMutation, useQueryClient } from 'react-query'
import { dropKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const updateDiscoPartner = async (id, fields) =>
  api.post(`drop/updateDiscoPartner`, { json: { id, ...fields } }).json()

const useUpdateDiscoPartner = id => {
  const queryClient = useQueryClient()

  return useMutation(async fields => updateDiscoPartner(id, fields), {
    onSuccess: () => {
      queryClient.invalidateQueries(dropKeys.detail(id))
    },
  })
}

export default useUpdateDiscoPartner
