/**
 * @file List item used in booking rows
 * @author Alwyn Tan
 */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DarkerGray } from 'src/constants/colors'
import Text from 'src/componentsV2/atoms/Text'
import Button from 'src/componentsV2/atoms/Button'

const Container = styled.div`
  width: 300px;
  padding: 16px;
  background: ${DarkerGray};
  border-radius: 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TextContainer = styled.div``

const BookingV2ListItem = ({ bookingV2, onViewClick = () => {} }) => {
  const items = useMemo(
    () =>
      (bookingV2.inventories || []).reduce(
        (acc, curr) => acc + curr.quantity,
        0
      ),
    [bookingV2]
  )

  return (
    <Container>
      <TextContainer>
        <Text type="h5">{bookingV2?.dropTitle}</Text>
        <Text type="button2" style={{ paddingTop: 6 }}>
          {bookingV2?.user?.name}
        </Text>
        <Text type="p1" style={{ paddingTop: 8 }}>
          {items} item{items === 1 ? '' : 's'} requested
        </Text>
      </TextContainer>
      <Button type="secondary" size="small" onClick={onViewClick}>
        View
      </Button>
    </Container>
  )
}

BookingV2ListItem.propTypes = {
  bookingV2: PropTypes.shape({
    id: PropTypes.string,
    dropTitle: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    inventories: PropTypes.arrayOf(
      PropTypes.shape({
        inventory: PropTypes.string,
        quantity: PropTypes.number,
      })
    ),
  }),
  onViewClick: PropTypes.func,
}

export default BookingV2ListItem
