/**
 * @file Use get scheduled notification job query
 * @author Alwyn Tan
 */

import { useQuery } from 'react-query'
import { notificationKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const getScheduledNotification = async id =>
  api.get(`notification/schedule/${id}`).json()

const useGetScheduledNotification = id =>
  useQuery(
    notificationKeys.detail(id),
    async () => getScheduledNotification(id),
    { enabled: !!id }
  )

export default useGetScheduledNotification
