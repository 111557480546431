/**
 * @file Use get STL Inventories
 * @author Alwyn Tan
 */

import { useInfiniteQuery } from 'react-query'
import { stlInventoryKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

// default prevEndDate to be today onwards (to prevent buying old inventory)
const getSTLInventories = async (stlID, pageParam) =>
  api.get('stl-inventory', { searchParams: { stlID, ...pageParam } }).json()

const useGetSTLInventories = stlID =>
  useInfiniteQuery(
    stlInventoryKeys.list(stlID),
    async ({ pageParam }) => getSTLInventories(stlID, pageParam),
    {
      select: data =>
        data?.pages?.reduce(
          (acc, curr) => [...acc, ...curr.stlInventories],
          []
        ),
      getNextPageParam: prevPage => {
        if (prevPage.canLoadMore) {
          const prevSTLInventory =
            prevPage.stlInventories[(prevPage?.stlInventories.length || 0) - 1]
          return {
            prevID: prevSTLInventory?.id,
            prevEndDate: prevSTLInventory?.endDate,
          }
        }
        return undefined
      },
    }
  )

export default useGetSTLInventories
