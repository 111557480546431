/**
 * @file Logout Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import AuthContext from 'src/contexts/AuthContext'
import api from 'src/utils/api'

const postLogout = async () =>
  api.post('user/logout', { credentials: 'include' }).json()

const useLogout = () => {
  const { setUser } = useContext(AuthContext)

  return useMutation(async () => {
    await postLogout()
    setUser(null)
    return {}
  })
}

export default useLogout
