/**
 * @file Component for the new STL inventory card
 * @author Alwyn Tan
 */

import React from 'react'
import { Check, Package, X } from 'react-feather'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DarkGray, Green, Purple, Red } from 'src/constants/colors'
import useCreateSTLInventory from 'src/hooks/query/stl/useCreateSTLInventory'
import TinyInput from './inputs/TinyInput'
import TinyDateInput from './inputs/TinyDateInput'

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`

const Container = styled.div`
  width: 250px;
  background-color: #ffffff1a;
  padding: 20px;
  border-radius: 20px;
  border: 2px dashed ${Purple};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const FormButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;

  > *:first-child {
    margin-right: 15px;
  }
`

const FormButton = styled.div`
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
  background-color: ${DarkGray};
`

const NewSTLInventoryCard = ({ stlID, onCancel = () => {} }) => {
  const { control, handleSubmit } = useForm()

  const createSTLInventoryMutation = useCreateSTLInventory(stlID)

  const onSubmit = async fields => {
    const toastID = toast.loading('Creating')
    try {
      await createSTLInventoryMutation.mutateAsync(fields)
      toast.success('Success', { id: toastID })
      onCancel()
    } catch (e) {
      toast.error(
        `Failed. Error ${
          (await e?.response?.json())?.error?.message || e.message
        }. Make sure end date is after start date`,
        { id: toastID }
      )
    }
  }

  return (
    <RowWrapper>
      <Container>
        <Row style={{ justifyContent: 'space-between' }}>
          <TinyDateInput
            name="startDate"
            placeholder="Start"
            control={control}
            style={{ width: '49%' }}
          />
          <TinyDateInput
            name="endDate"
            placeholder="End"
            control={control}
            style={{ width: '49%' }}
          />
        </Row>
        <Row style={{ paddingTop: 15, paddingBottom: 5 }}>
          <Package />
          <TinyInput
            name="quantity"
            placeholder="Inventory Count"
            required
            control={control}
            style={{ width: 180, marginLeft: 10 }}
            inputProps={{ type: 'number' }}
          />
        </Row>
      </Container>
      <FormButtonBox>
        <FormButton style={{ color: Green }} onClick={handleSubmit(onSubmit)}>
          <Check />
        </FormButton>
        <FormButton style={{ color: Red }} onClick={onCancel}>
          <X />
        </FormButton>
      </FormButtonBox>
    </RowWrapper>
  )
}

NewSTLInventoryCard.propTypes = {
  stlID: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
}

export default NewSTLInventoryCard
