/**
 * @file STL Inventory Card
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { Check, Package, X } from 'react-feather'
import { DarkGray, Green, Purple, Red } from 'src/constants/colors'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import useUpdateSTLInventory from 'src/hooks/query/stl/useUpdateSTLInventory'
import TinyInput from './inputs/TinyInput'

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Container = styled.div`
  padding: 20px;
  border-radius: 20px;
  background-color: ${DarkGray};
  align-self: flex-start;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  border: 2px solid transparent;
  border-color: ${({ active }) => (active ? Purple : 'transparent')};
  display: flex;
  flex-direction: column;
  width: 230px;
  opacity: ${({ quantity, active }) => (!active && quantity === 0 ? 0.5 : 1)};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const FormButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;

  > *:first-child {
    margin-right: 15px;
  }
`

const FormButton = styled.div`
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
  background-color: ${DarkGray};
`

const STLInventoryCard = ({
  stlInventory,
  style,
  active,
  onClick,
  onCancel,
}) => {
  const { handleSubmit, control, reset } = useForm()
  const updateSTLInventoryMutation = useUpdateSTLInventory(stlInventory?.id)

  useEffect(() => {
    if (!active) reset({ ...stlInventory })
  }, [active, stlInventory, reset])

  if (!stlInventory) return null

  const onSubmit = async fields => {
    const toastID = toast.loading('Updating')
    try {
      await updateSTLInventoryMutation.mutateAsync(fields)
      toast.success('Success', { id: toastID })
      onCancel()
    } catch (e) {
      toast.error(`Failed. Error ${e?.message}`, { id: toastID })
    }
  }

  return (
    <RowWrapper>
      <Container
        style={style}
        active={active}
        quantity={stlInventory?.quantity}
        onClick={onClick}
      >
        <h5 style={{ marginBottom: 5 }}>
          {dayjs(stlInventory.startDate).tz().format('h:mm A')} -{' '}
          {dayjs(stlInventory.endDate).tz().format('h:mm A')}
        </h5>
        <Row style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Package style={{ marginRight: 10 }} />
          {active ? (
            <>
              <TinyInput
                name="quantity"
                control={control}
                defaultValue={stlInventory.quantity}
                disabled={!active}
                inputProps={{ type: 'number' }}
              />
              <h6 style={{ paddingLeft: 5 }}>available</h6>
            </>
          ) : (
            <h6>{stlInventory.quantity} available</h6>
          )}
        </Row>
      </Container>
      {active && (
        <FormButtonBox>
          <FormButton style={{ color: Green }} onClick={handleSubmit(onSubmit)}>
            <Check />
          </FormButton>
          <FormButton style={{ color: Red }} onClick={onCancel}>
            <X />
          </FormButton>
        </FormButtonBox>
      )}
    </RowWrapper>
  )
}

STLInventoryCard.propTypes = {
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  stlInventory: PropTypes.shape({
    id: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    quantity: PropTypes.number,
  }),
  active: PropTypes.bool,
  onClick: PropTypes.func,
  onCancel: PropTypes.func,
}

export default STLInventoryCard
