/**
 * @file Drop Table
 * @author Alwyn Tan
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import useGetDrops from 'src/hooks/query/drop/useGetDrops'
import { useSearchParams } from 'react-router-dom'
import Table from '../molecules/Table'

const Container = styled.div``

const EndOfListText = styled.p`
  padding-left: 10px;
  padding-top: 20px;
`

const DropTable = ({ status }) => {
  const infiniteTriggerRef = useRef(null)
  const [activeDrop, setActiveDrop] = useState('')
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useGetDrops(status)
  const [, setSearchParams] = useSearchParams()

  const events = data || []

  const handleDropSelect = useCallback(
    drop => {
      setActiveDrop(drop)
      setSearchParams({ selected: drop.id })
    },
    [setActiveDrop, setSearchParams]
  )

  useEffect(() => {
    if (hasNextPage && infiniteTriggerRef.current && !isFetchingNextPage) {
      const observer = new IntersectionObserver(
        entries =>
          entries.forEach(entry => entry.isIntersecting && fetchNextPage()),
        { rootMargin: '0px', threshold: 1.0 }
      )

      const el = infiniteTriggerRef.current

      observer.observe(el)
      return () => observer.unobserve(el)
    }
    return () => {}
  }, [hasNextPage, fetchNextPage, isFetchingNextPage])

  const columns = useMemo(
    () => [
      {
        Header: 'Preview',
        accessor: 'thumbnail',
        // eslint-disable-next-line
        Cell: ({ cell: { value } }) => <img src={value} width={25} />,
      },
      { Header: 'Title', accessor: 'title' },
      {
        Header: 'Details',
        accessor: 'description',
        // eslint-disable-next-line
        Cell: ({ cell: { value } }) => (
          <p style={{ whiteSpace: 'pre-line' }}>{value}</p>
        ),
      },
      {
        Header: 'Active Date',
        accessor: v =>
          v.activeDate &&
          dayjs(v.activeDate).tz().format('DD MMM YYYY h:mma z'),
      },
      {
        Header: 'Expiry Date',
        accessor: v =>
          v.expiryDate &&
          dayjs(v.expiryDate).tz().format('DD MMM YYYY h:mma z'),
      },
      { Header: 'Location Title', accessor: 'location' },
      { Header: 'Address', accessor: 'locationV2.formatted' },
      { Header: 'Category', accessor: 'categories' },
      { Header: 'Website Link', accessor: 'link' },
      { Header: 'Inventory Description', accessor: 'inventoryDescription' },
      {
        Header: 'Bookable',
        accessor: 'isBookable',
        // eslint-disable-next-line
        Cell: ({ cell: { value } }) => (value ? <p>✅</p> : null),
      },
    ],
    []
  )

  return (
    <Container>
      <Table
        style={{ minWidth: 1200 }}
        columns={columns}
        data={events}
        activeID={activeDrop.id}
        onSelect={handleDropSelect}
      />
      {!hasNextPage && (
        <EndOfListText>That's it! Total: {data?.length} rows</EndOfListText>
      )}
      <div ref={infiniteTriggerRef} />
    </Container>
  )
}

DropTable.propTypes = {
  status: PropTypes.string,
}

export default DropTable
