/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import toast from 'react-hot-toast'
import Button from 'src/componentsV2/atoms/Button'
import useConfirmPass from 'src/hooks/query/pass/useConfirmPass'
import Input from 'src/components/simple/Input'
import { useForm } from 'react-hook-form'

const FullWidthContainer = styled(motion.div)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 40px;
  display: flex;
  justify-content: center;
  pointer-events: none;
`

const Container = styled.div`
  padding: 24px 30px;
  background-color: #262627;
  border-radius: 12px;
  pointer-events: all;
  display: flex;
  flex-direction: column;

  > h3 {
    padding-bottom: 12px;
  }

  > p {
    padding-bottom: 5px;
  }
`

const PassConfirmModal = ({ pass }) => {
  const { control, handleSubmit } = useForm({ mode: 'onChange' })
  const confirmPassMutation = useConfirmPass()

  const onSubmit = async ({ confirmationLink }) => {
    const toastID = toast.loading('Confirming Pass...')
    try {
      await confirmPassMutation.mutateAsync({
        passID: pass?.id,
        confirmationLink,
      })
      toast.success('Confirmed!', { id: toastID })
    } catch (e) {
      toast.error('Failed', { id: toastID })
    }
  }

  return (
    <FullWidthContainer>
      <Container>
        <h3>Pass Info</h3>
        <p>
          <strong>{pass?.title}</strong>
        </p>
        <p style={{ paddingTop: 10, paddingBottom: 20 }}>
          For: {pass?.user?.name}
          <br />
          User ID: {pass?.user?.id}
        </p>
        <Input
          name="confirmationLink"
          placeholder="Enter Confirmation Link"
          type="url"
          control={control}
        />
        <Button
          size="small"
          style={{ alignSelf: 'center', marginTop: 20 }}
          onClick={handleSubmit(onSubmit)}
        >
          Confirm
        </Button>
      </Container>
    </FullWidthContainer>
  )
}

PassConfirmModal.propTypes = {
  pass: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
    user: PropTypes.string,
  }),
}

export default PassConfirmModal
