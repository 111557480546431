/**
 * @file Text component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const H1 = styled.h1`
  font-family: Hobeaux;
  font-style: normal;
  font-weight: 900;
  font-size: 1.733rem;
  margin: 0;
  color: ${({ theme }) => theme.Text};
`

const H2 = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 2rem;
  margin: 0;
  color: ${({ theme }) => theme.Text};
`

const H3 = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.867rem;
  margin: 0;
  color: ${({ theme }) => theme.Text};
`

const H4 = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 1.467rem;
  margin: 0;
  color: ${({ theme }) => theme.Text};
  line-height: 123%;
`

const H5 = styled.h5`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.Text};
`

const H6 = styled.h6`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.133rem;
  margin: 0;
  color: ${({ theme }) => theme.Text};
`

const P1 = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
  color: ${({ theme }) => theme.Text};
  line-height: 150%;
`

const P2 = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 14.52px;
  margin: 0;
  color: ${({ theme }) => theme.Text};
`

const Button1 = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0;
  color: ${({ theme }) => theme.Text};
`

const Button2 = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.067rem;
  margin: 0;
  color: ${({ theme }) => theme.Text};
  line-height: 125%;
`

const Text = ({ type = 'p1', children, ...props }) => {
  switch (type) {
    case 'h1':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <H1 {...props}>{children}</H1>

    case 'h2':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <H2 {...props}>{children}</H2>

    case 'h3':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <H3 {...props}>{children}</H3>

    case 'h4':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <H4 {...props}>{children}</H4>

    case 'h5':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <H5 {...props}>{children}</H5>

    case 'h6':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <H6 {...props}>{children}</H6>

    case 'p1':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <P1 {...props}>{children}</P1>

    case 'p2':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <P2 {...props}>{children}</P2>

    case 'button1':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Button1 {...props}>{children}</Button1>

    case 'button2':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Button2 {...props}>{children}</Button2>

    default:
      return null
  }
}

Text.propTypes = {
  type: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p1',
    'p2',
    'button1',
    'button2',
  ]),
  children: PropTypes.node.isRequired,
}

export default Text
