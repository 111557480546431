/* eslint-disable no-nested-ternary */
/**
 * @file Select input
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'
import Select from 'react-select'
import { DarkerGray, DarkGray, Red } from 'src/constants/colors'
import styled from 'styled-components'

const customStyles = {
  container: provided => ({
    ...provided,
  }),
  control: provided => ({
    ...provided,
    backgroundColor: '#ffffff1a',
    border: 0,
    minHeight: 50,
    padding: '7px 0 7px 10px',
    width: '100%',
    borderRadius: 8,
    boxShadow: 'none',
  }),
  input: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    opacity: 0.4,
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    padding: 15,
    backgroundColor: state.isFocused ? DarkerGray : 'transparent',

    ':active': {
      ...provided[':active'],
      backgroundColor: state.isFocused ? DarkerGray : 'transparent',
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: DarkerGray,
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: 'white',
    fontStyle: 'normal',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '0.8rem',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: DarkGray,
  }),
  menuList: provided => ({
    ...provided,
    borderRadius: 8,
  }),
}

const Container = styled.div`
  position: relative;
`

const ErrorBoundary = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  border: 4px solid ${Red};
  border-radius: 8px 8px 0 0;
  pointer-events: none;
  z-index: 1;
`

const Error = styled.div`
  background-color: ${Red};
  padding: 5px 15px 10px 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
`

const TagInput = ({
  options,
  required = false,
  defaultValue,
  placeholder = '',
  name,
  control,
}) => {
  const transform = {
    input: arr => arr?.map(v => ({ label: v, value: v })),
    output: arr => arr?.map(v => v.value),
  }

  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
    rules: { required },
  })

  return (
    <>
      <Container>
        {fieldState.error && <ErrorBoundary />}
        <Select
          options={options}
          styles={customStyles}
          isMulti
          placeholder={placeholder}
          onChange={v => field.onChange(transform.output(v))}
          onBlur={field.onBlur}
          value={transform.input(field.value)}
          name={field.name}
          ref={field.ref}
        />
      </Container>
      {fieldState.error && (
        <Error>
          <span>{fieldState.error.message}</span>
        </Error>
      )}
    </>
  )
}

TagInput.propTypes = {
  options: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  required: PropTypes.bool,
  defaultValue: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  placeholder: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

export default TagInput
