/**
 * @file Use get drop query
 * @author Alwyn Tan
 */

import { useQuery } from 'react-query'
import { dropKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const getDrop = async id => {
  const searchParams = { showActiveDeal: true }
  return api.get(`drop/${id}`, { searchParams }).json()
}

const useGetDrop = id =>
  useQuery(dropKeys.detail(id), async () => getDrop(id), {
    enabled: !!id,
  })

export default useGetDrop
