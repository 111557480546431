/**
 * @file
 * @author Alwyn Tan
 */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DarkerGray } from 'src/constants/colors'
import { useForm } from 'react-hook-form'
import Text from 'src/componentsV2/atoms/Text'
import Button from 'src/componentsV2/atoms/Button'
import FormItem from 'src/componentsV2/molecules/FormItem'
import { BOOKING_V2_STATUS } from 'src/constants/booking'
import SelectInput from 'src/componentsV2/molecules/inputs/SelectInput'
import DateInput from 'src/componentsV2/molecules/inputs/DateInput'
import useUpdateBookingV2 from 'src/hooks/query/bookingV2/useUpdateBookingV2'
import toast from 'react-hot-toast'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Backdrop = styled.div`
  position: absolute;
  z-index: 0;
  background-color: #00000080;
  height: 100%;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
`

const Modal = styled.div`
  padding: 24px;
  background-color: ${DarkerGray};
  z-index: 1;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
  user-select: none;
  max-height: 90%;
  overflow-y: auto;
`

const EditBookingModal = ({ bookingV2, onClose }) => {
  const { control, handleSubmit, formState } = useForm({ mode: 'onChange' })
  const updateBookingV2Mutation = useUpdateBookingV2(bookingV2.id)

  const onSubmit = async ({ status, scheduledDate }) => {
    const toastID = toast.loading('Editing booking')
    try {
      await updateBookingV2Mutation.mutateAsync({ status, scheduledDate })
      toast.success('Edited booking', { id: toastID })
    } catch {
      toast.error('Failed to edit booking', { id: toastID })
    }
    onClose()
  }

  const statusOptions = useMemo(
    () => Object.values(BOOKING_V2_STATUS).map(v => ({ label: v, value: v })),
    []
  )

  if (!bookingV2) return null

  return (
    <Container>
      <Backdrop onClick={onClose} />
      <Modal>
        <Text type="h5" style={{ paddingBottom: 10 }}>
          Edit Booking
        </Text>
        <Text type="button2" style={{ paddingBottom: 10 }}>
          User: {bookingV2.user.name}
        </Text>
        <FormItem
          title="Status"
          input={
            <SelectInput
              name="status"
              options={statusOptions}
              defaultValue={bookingV2?.status || BOOKING_V2_STATUS.PENDING}
              control={control}
              placeholder="Choose status"
              required
            />
          }
        />
        <Text type="p2" style={{ opacity: 0.5, marginTop: -10 }}>
          * Confirmed statuses will lock in the drop and prevent future changes
        </Text>
        <Row>
          <FormItem
            title="Date & Time"
            input={
              <DateInput
                name="scheduledDate"
                placeholder="Select Date"
                defaultValue={bookingV2.scheduledDate}
                control={control}
              />
            }
          />
        </Row>
        <Button
          size="medium"
          disabled={!formState.isValid || !formState.isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          Confirm
        </Button>
      </Modal>
    </Container>
  )
}

EditBookingModal.propTypes = {
  bookingV2: PropTypes.shape({
    id: PropTypes.string,
    user: PropTypes.shape({ name: PropTypes.string }),
    status: PropTypes.string,
    title: PropTypes.string,
    scheduledDate: PropTypes.string,
  }),
  onClose: PropTypes.func,
}

export default EditBookingModal
