/**
 * @file Get Events query
 * @author Jordan Stipp
 */

import dayjs from 'dayjs'
import { useInfiniteQuery } from 'react-query'
import { eventKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const getEvents = async (status, prevItem) => {
  const searchParams = { ...prevItem, showAll: true }
  if (status === 'past') searchParams.endDateFilter = dayjs().toISOString()
  else if (status === 'upcoming')
    searchParams.startDateFilter = dayjs().toISOString()

  return api.get('event/', { searchParams }).json()
}

const useGetEvents = status =>
  useInfiniteQuery(
    eventKeys.list(status),
    async ({ pageParam }) => getEvents(status, pageParam),
    {
      select: data =>
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.events], []),
      getNextPageParam: prevPage => {
        if (prevPage?.canLoadMore) {
          return {
            prevID: prevPage?.events[(prevPage?.events.length || 0) - 1]?.id,
            prevNextAvailableStartDate:
              prevPage?.events[(prevPage?.events.length || 0) - 1]
                ?.nextAvailableStartDate,
          }
        }
        return undefined
      },
    }
  )
export default useGetEvents
