/**
 * @file Creates an EventSeriesInstance
 * @author Jordan Stipp
 */

import { serialize } from 'object-to-formdata'
import { useMutation, useQueryClient } from 'react-query'
import { eventKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const createEventSeriesInstance = async (seriesID, fields) =>
  api
    .post('event/series-instance', {
      body: serialize({ ...fields, seriesID }, { indices: true }),
      timeout: 60000,
      prefixUrl: process.env.REACT_APP_API_URL,
    })
    .json()

const useCreateEventSeriesInstance = seriesID => {
  const queryClient = useQueryClient()

  return useMutation(
    async fields => createEventSeriesInstance(seriesID, fields),
    {
      onSuccess: ({ event }) => {
        queryClient.invalidateQueries(eventKeys.eventSeriesInstanceLists())
        queryClient.invalidateQueries(eventKeys.detail(seriesID))
        return { event }
      },
    }
  )
}

export default useCreateEventSeriesInstance
