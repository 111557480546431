/**
 * @file Passes Page
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import PassesTable from '../templates/PassesTable'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  padding: 40px 0px 0px 40px;
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  padding-right: 40px;
`

const Passes = () => (
  <Container>
    <Content>
      <h2 style={{ marginRight: 20 }}>
        Pass Management (PENDING & Non-Expired)
      </h2>
      <InnerContainer>
        <PassesTable />
      </InnerContainer>
    </Content>
  </Container>
)

export default Passes
