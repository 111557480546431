/** @file Toggle
 *  @author jstipp
 */
/* eslint-disable */

import React, { useState } from 'react'
import PropTypes, { oneOfType } from 'prop-types'
import styled from 'styled-components'
import { Purple, Red } from 'src/constants/colors'
import Switch from 'react-switch'
import { useController } from 'react-hook-form'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`

const ToggleInput = ({
  size,
  children,
  disabled = false,
  defaultValue = false,
  control,
  name,
  style,
  onChange,
}) => {
  const handleChange = nextChecked => field.onChange(nextChecked)

  const { field } = useController({
    control,
    name,
    defaultValue,
    shouldUnregister: true,
  })

  return (
    <Container style={style}>
      {children && <span style={{ color: 'white' }}>{children}</span>}
      <Switch
        onChange={handleChange}
        checked={field?.value}
        onColor={Purple}
        offColor={Red}
        handleDiameter={25}
        height={30}
        width={60}
        activeBoxShadow="0px"
        checkedIcon={false}
      />
    </Container>
  )
}

ToggleInput.propTypes = {
  label: PropTypes.string,
  style: oneOfType([PropTypes.object, PropTypes.array]),
}

ToggleInput.defaultProps = {
  style: {},
}

export default ToggleInput
