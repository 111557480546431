/**
 * @file Preview for a drop
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import pin from 'src/images/pin.png'

const Container = styled.div`
  min-height: 526px;
  height: 526px;
  width: 243px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Video = styled.video`
  object-fit: cover;
  border-radius: 8px;
  position: absolute;
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  padding: 16px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Descriptor = styled.div`
  margin-top: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 6px;
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  max-width: 100%;
  align-items: center;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    > span {
      font-style: italic;
      opacity: 0.5;
    }
  }
`

const Icon = styled.div`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px;
  height: 12.5px;
  min-width: 10px;
  margin-right: 6px;
`

const DropPreview = ({ drop, style }) => {
  if (!drop) return null

  return (
    <Container style={style}>
      <Video src={drop.video} poster={drop.thumbnail} autoPlay />
      <Content>
        <h4>{drop.title}</h4>
        <Descriptor>
          <Icon src={pin} />
          <span>
            {drop.location || (drop.address || '').split(',')[0] || (
              <span>Location or Address</span>
            )}
          </span>
        </Descriptor>
      </Content>
    </Container>
  )
}

DropPreview.propTypes = {
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  drop: PropTypes.shape({
    title: PropTypes.string,
    thumbnail: PropTypes.string,
    video: PropTypes.string,
    location: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
}

export default DropPreview
