/**
 * @file Side Menu Component
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { DarkerGray, Red } from 'src/constants/colors'
import { LogOut } from 'react-feather'
import useLogout from 'src/hooks/query/user/useLogout'
import SideMenuTabItem from '../molecules/SideMenuTabItem'

const Container = styled.div`
  background-color: ${DarkerGray};
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;

  > a {
    text-decoration: none;
  }

  > a:not(:last-child) {
    margin-bottom: 40px;
  }
`

const DiscoTitle = styled(NavLink)`
  color: white;
  margin-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
`

const SideMenu = () => {
  const logoutMutation = useLogout()

  const handleLogout = () => {
    logoutMutation.mutate()
  }

  return (
    <Container>
      <Nav>
        <DiscoTitle to="/">
          <h1>DISCO</h1>
        </DiscoTitle>
        <SideMenuTabItem to="booking" title="Booking" />
        <SideMenuTabItem to="drop" title="Drops" />
        <SideMenuTabItem to="event" title="Events" />
        <SideMenuTabItem to="waitlist" title="Waitlist" />
        <SideMenuTabItem to="waitlist-code" title="Waitlist Code" />
        <SideMenuTabItem to="notifications" title="Notifs" />
        <SideMenuTabItem to="stl" title="STL" />
        <SideMenuTabItem to="passes" title="Passes" />
      </Nav>
      <LogOut
        color={Red}
        onClick={handleLogout}
        style={{ cursor: 'pointer' }}
      />
    </Container>
  )
}

export default SideMenu
