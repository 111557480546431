/**
 * @file Get Event Emails
 * @author Jordan Stipp
 */

import { useMutation } from 'react-query'
import api from 'src/utils/api'

const getCSV = async id => api.get(`event/attendees-csv//${id}`).blob()

const useEventAttendeesCSV = () => useMutation(id => getCSV(id))

export default useEventAttendeesCSV
