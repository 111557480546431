/* eslint-disable no-nested-ternary */
/**
 * @file Drop Details Menu
 * @author Alwyn Tan
 */

import { useNavigate, useSearchParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import { DarkerGray, Purple } from 'src/constants/colors'
import { useForm } from 'react-hook-form'
import useUpdateEvent from 'src/hooks/query/event/useUpdateEvent'
import useCreateEvent from 'src/hooks/query/event/useCreateEvent'
import useUpdateEventSeriesInstance from 'src/hooks/query/event/useUpdateEventSeriesInstance'
import FormItem from '../molecules/FormItem'
import TextInput from '../molecules/inputs/TextInput'
import TextAreaInput from '../molecules/inputs/TextAreaInput'
import FileInput from '../molecules/inputs/FileInput'
import DateInput from '../molecules/inputs/DateInput'
import AddressInput from '../molecules/inputs/AddressInput'
import NumberInput from '../molecules/inputs/NumberInput'
import Button from '../atoms/Button'
import ToggleInput from '../atoms/Toggle'

const Container = styled.div`
  padding: 40px;
  background-color: ${DarkerGray};
  width: 410px;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: auto;
`

const AddEventSeriesInstancesButton = styled.p`
  color: ${Purple};
  cursor: pointer;
  padding-top: 5px;
`

const Form = styled.form`
  margin-top: 20px;
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`

const EventDetailsMenu = ({ event }) => {
  const { control, handleSubmit, reset, watch, isSubmitting } = useForm()
  const updateEventMutation = useUpdateEvent(event?.id)
  const updateEventSeriesInstanceMutation = useUpdateEventSeriesInstance(
    event?.id,
    event?.seriesID
  )
  const createEventMutation = useCreateEvent()
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!event) reset({})
    else
      reset({
        ...event,
        isSeries: undefined,
        minTicketPrice: event?.minTicketPrice || 0,
        maxTicketPrice: event?.maxTicketPrice || 0,
      })
  }, [event, reset])

  const onSubmit = async values => {
    const toastID = toast.loading('Loading')
    try {
      let createdEvent
      if (event) {
        if (event?.seriesID) {
          await updateEventSeriesInstanceMutation.mutateAsync(values)
        } else {
          await updateEventMutation.mutateAsync(values)
        }
      } else
        createdEvent = (await createEventMutation.mutateAsync(values)).event
      toast.success('Success', { id: toastID })
      if (createdEvent) {
        navigate(createdEvent?.id)
      }
    } catch (e) {
      toast.error('Failed', { id: toastID })
    }
  }

  const handleAddEventSeriesInstance = () => {
    setSearchParams({ showInstancePanel: true })
  }

  return (
    <Container>
      <h4>
        {event
          ? event?.seriesID
            ? 'Update Instance'
            : 'Update Event'
          : 'Create Event'}
      </h4>
      {event?.isSeries && (
        <AddEventSeriesInstancesButton
          className="button2"
          onClick={handleAddEventSeriesInstance}
        >
          Add Events to EventSeries
        </AddEventSeriesInstancesButton>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        {!event?.seriesID && (
          <>
            <FormItem
              title="Title"
              input={
                <TextInput
                  name="title"
                  defaultValue={event?.title}
                  control={control}
                  required
                  placeholder="Enter Title"
                />
              }
            />
            <FormItem
              title="Details"
              input={
                <TextAreaInput
                  name="description"
                  defaultValue={event?.description}
                  control={control}
                  required
                  placeholder="Enter Description"
                />
              }
            />
            <FormItem
              title="Flyer"
              input={
                <FileInput name="flyer" control={control} required={!event} />
              }
            />
            <FormItem
              title="Video Flyer"
              input={<FileInput name="videoFlyer" control={control} />}
            />
            {!event && (
              <FormItem
                title="isSeries"
                input={
                  <ToggleInput
                    control={control}
                    name="isSeries"
                    defaultValue={event?.isSeries}
                  />
                }
              />
            )}
          </>
        )}
        {!watch('isSeries') && !event?.isSeries && (
          <>
            <FormItem
              title="Start Date"
              input={
                <DateInput
                  placeholder="Select Date"
                  name="startDate"
                  defaultValue={event?.startDate}
                  control={control}
                />
              }
            />
            <FormItem
              title="End Date"
              input={
                <DateInput
                  placeholder="Select Date"
                  name="endDate"
                  defaultValue={event?.endDate}
                  control={control}
                />
              }
            />
          </>
        )}
        {!event?.seriesID && (
          <>
            <FormItem
              title="Website"
              input={
                <TextInput
                  name="website"
                  defaultValue={event?.website}
                  control={control}
                  placeholder="Enter a Website"
                />
              }
            />
            <FormItem
              title="Address"
              input={
                <AddressInput
                  name="location"
                  defaultValue={event?.location?.formatted}
                  control={control}
                  placeholder="Enter an address"
                  required
                />
              }
            />
            <FormItem
              title="Minimum Ticket Price"
              input={
                <NumberInput
                  name="minTicketPrice"
                  defaultValue={event?.minTicketPrice || 0}
                  control={control}
                  placeholder="Add minimum price"
                  numberType="price"
                />
              }
            />
            <FormItem
              title="Maximum Ticket Price"
              input={
                <NumberInput
                  name="maxTicketPrice"
                  defaultValue={event?.maxTicketPrice || 0}
                  control={control}
                  placeholder="Add maximum price"
                  numberType="price"
                />
              }
            />
            <FormItem />
            <FormItem
              title="RSVP Required"
              input={
                <ToggleInput
                  control={control}
                  name="rsvpRequired"
                  defaultValue={event?.rsvpRequired}
                />
              }
            />
            <FormItem
              title="Tickets Required"
              input={
                <ToggleInput
                  control={control}
                  name="ticketsRequired"
                  defaultValue={event?.ticketsRequired}
                />
              }
            />
            <FormItem
              title="Ticket Link"
              input={
                <TextInput
                  name="ticketLink"
                  defaultValue={event?.ticketLink}
                  control={control}
                  placeholder="Enter a url"
                />
              }
            />
          </>
        )}
        <Button disabled={isSubmitting}>Submit</Button>
      </Form>
    </Container>
  )
}

EventDetailsMenu.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    website: PropTypes.string,
    ticketLink: PropTypes.string,
    location: PropTypes.shape({
      formatted: PropTypes.string,
    }),
    minTicketPrice: PropTypes.number,
    maxTicketPrice: PropTypes.number,
    rsvpRequired: PropTypes.bool,
    ticketsRequired: PropTypes.bool,
    isSeries: PropTypes.bool,
    seriesID: PropTypes.string,
  }),
}

export default EventDetailsMenu
