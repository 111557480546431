/**
 * @file File input component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useController } from 'react-hook-form'
import { Red } from 'src/constants/colors'

const Container = styled.div`
  height: 50px;
  width: 100%;
  background-color: #ffffff1a;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
  border: 4px solid ${Red};
  border-color: ${({ error }) => (error ? Red : 'transparent')};
  border-radius: ${({ error }) => (error ? '8px 8px 0 0' : '8px')};

  input {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    appearance: none;
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

const UploadButton = styled.div`
  padding: 5px 10px;
  background-color: #ffffff33;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`

const Error = styled.div`
  background-color: ${Red};
  padding: 5px 15px 10px 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
`

const FileInput = ({
  placeholder = 'Add a file',
  control,
  name,
  required = false,
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue: '',
    rules: { required },
  })

  const transform = {
    input: v => v?.name || '',
    output: v => v.target.files[0],
  }

  return (
    <>
      <Container error={fieldState.error}>
        <input
          type="file"
          onChange={e => field.onChange(transform.output(e))}
          onBlur={field.onBlur}
          name={field.name}
          ref={field.ref}
        />
        <p
          style={{ marginRight: 'auto', opacity: field?.value?.name ? 1 : 0.4 }}
        >
          {field?.value?.name ? field?.value?.name : placeholder}
        </p>
        <UploadButton>
          <p className="p2">Choose File</p>
        </UploadButton>
      </Container>
      {fieldState.error && (
        <Error>
          <span>{fieldState.error.message}</span>
        </Error>
      )}
    </>
  )
}

FileInput.propTypes = {
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default FileInput
