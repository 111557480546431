/**
 * @file Get Event Emails
 * @author Jordan Stipp
 */

import { useMutation } from 'react-query'
import api from 'src/utils/api'

const getEmails = async id => {
  const searchParams = { prop: 'email' }

  return api.get(`event/attendees-props/${id}`, { searchParams }).json()
}

const useEventEmails = () => useMutation(id => getEmails(id))

export default useEventEmails
