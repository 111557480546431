/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import PageNavItem from 'src/componentsV2/molecules/PageNavItem'
import { Outlet } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  min-width: 0;
  padding: 40px 0px 0px 40px;
`

const NavBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  overflow: auto;
`

const BookingV2 = () => (
  <Container>
    <NavBar>
      <PageNavItem to="pending" title="Pending" />
      <PageNavItem to="in-progress" title="In Progress" />
      <PageNavItem to="confirmed" title="Confirmed" />
      <PageNavItem to="canceled" title="Canceled" />
    </NavBar>
    <ContentContainer>
      <Outlet />
    </ContentContainer>
  </Container>
)

export default BookingV2
