/**
 * @file Update Waitlist Code Hook
 * @author Jordan Stipp
 */

import { useMutation, useQueryClient } from 'react-query'
import { waitlistCodeKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const putUpdateWaitlistCode = async waitlistCodeProps => {
  const { id, usesLeft, isActive } = waitlistCodeProps
  return api
    .put(`waitlist-code/${id}`, {
      json: { usesLeft, isActive },
    })
    .json()
}

const useUpdateWaitlistCode = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async waitlistCodeProps => putUpdateWaitlistCode(waitlistCodeProps),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitlistCodeKeys.all)
      },
    }
  )
}

export default useUpdateWaitlistCode
