/**
 * @file Waitlist Code Screen
 * @author Jordan Stipp
 */

import React from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import PageNavItem from 'src/componentsV2/molecules/PageNavItem'

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  min-width: 0;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px 0px 40px;
`

const NavBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const TableContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
`

const WaitlistCode = () => (
  <Container>
    <NavBar>
      <PageNavItem to="active" title="Active" />
      <PageNavItem to="inactive" title="Inactive" />
    </NavBar>
    <TableContainer>
      <Outlet />
    </TableContainer>
  </Container>
)

export default WaitlistCode
