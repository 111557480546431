/**
 * @file Drop Details Menu
 * @author Alwyn Tan
 */

import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import { DarkerGray, DarkGray, Purple } from 'src/constants/colors'
import { KNOWN_PARTNERS } from 'src/constants/partners'
import useCreateSTL from 'src/hooks/query/stl/useCreateSTL'
import useUpdateSTL from 'src/hooks/query/stl/useUpdateSTL'
import FormItem from '../molecules/FormItem'
import TextInput from '../molecules/inputs/TextInput'
import TextAreaInput from '../molecules/inputs/TextAreaInput'
import AddressInput from '../molecules/inputs/AddressInput'
import NumberInput from '../molecules/inputs/NumberInput'
import Button from '../atoms/Button'
import SelectInput from '../molecules/inputs/SelectInput'

const Container = styled.div`
  padding: 40px;
  background-color: ${DarkerGray};
  width: 410px;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: auto;
`

const InventoryButton = styled.p`
  color: ${Purple};
  cursor: pointer;
  padding-top: 5px;
`

const Form = styled.form`
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`

const STLPosterWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin: 20px 0 20px 0;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    border-radius: 10px;
    background-color: ${DarkGray};
  }
`

const STLDetailsMenu = ({ stl }) => {
  const [, setSearchParams] = useSearchParams()
  const { control, handleSubmit, reset } = useForm()

  const createSTLMutation = useCreateSTL()
  const updateSTLMutation = useUpdateSTL(stl?.id)

  const statusOptions = useMemo(
    () =>
      Object.entries(KNOWN_PARTNERS).map(([k, v]) => ({ label: v, value: k })),
    []
  )

  useEffect(() => {
    if (!stl) reset({})
    else reset({ ...stl })
  }, [stl, reset])

  const onSubmit = async values => {
    const toastID = toast.loading('Loading')

    try {
      if (stl) await updateSTLMutation.mutateAsync(values)
      else await createSTLMutation.mutateAsync(values)
      toast.success('Success', { id: toastID })
    } catch (e) {
      toast.error('Failed', { id: toastID })
    }
  }

  const handleManageInventoryClick = () => {
    setSearchParams({ selected: stl?.id, showInventory: true })
  }

  return (
    <Container>
      <h4>Details</h4>
      <InventoryButton className="button2" onClick={handleManageInventoryClick}>
        Manage Inventory
      </InventoryButton>
      <STLPosterWrapper>
        <img src={stl?.poster} alt="stl poster" />
      </STLPosterWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormItem
          title="Title"
          input={
            <TextAreaInput
              name="title"
              defaultValue={stl?.title}
              control={control}
              required
              placeholder="Enter Title"
            />
          }
        />
        <FormItem
          title="Details"
          input={
            <TextAreaInput
              name="description"
              defaultValue={stl?.description}
              control={control}
              required
              placeholder="Enter Description"
            />
          }
        />
        <FormItem
          title="Poster"
          input={
            <TextInput
              name="poster"
              defaultValue={stl?.poster}
              control={control}
              required
              placeholder="Enter Poster URL"
            />
          }
        />
        <FormItem
          title="Address"
          input={
            <AddressInput
              name="location"
              defaultValue={stl?.location?.formatted}
              control={control}
              placeholder="Enter an address"
              required
            />
          }
        />
        <FormItem
          title="Default Price"
          input={
            <NumberInput
              name="price"
              defaultValue={stl?.price}
              control={control}
              placeholder="Set single-pass price"
              numberType="price"
            />
          }
        />
        <FormItem
          title="Partner"
          input={
            <SelectInput
              name="partner"
              options={statusOptions}
              control={control}
              placeholder="Choose partner"
              required
            />
          }
        />
        <Button>Submit</Button>
      </Form>
    </Container>
  )
}

STLDetailsMenu.propTypes = {
  stl: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    poster: PropTypes.string,
    location: PropTypes.shape({ formatted: PropTypes.string }),
    price: PropTypes.number,
  }),
}

export default STLDetailsMenu
