/**
 * @file Waitlist Code Constants
 * @author Jordan Stipp
 */

// eslint-disable-next-line import/prefer-default-export
export const WAITLIST_CODE_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}
