/**
 * @file Gets scheduled notifications
 * @author Alwyn Tan
 */

import { useInfiniteQuery } from 'react-query'
import { notificationKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const getScheduledNotifications = async pageParam =>
  api.get('notification/schedule', { searchParams: { ...pageParam } }).json()

const useGetScheduledNotifications = () =>
  useInfiniteQuery(
    notificationKeys.list(),
    async ({ pageParam } = {}) => getScheduledNotifications(pageParam),
    {
      select: data =>
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.jobs], []),
      getNextPageParam: prevPage => {
        if (prevPage?.canLoadMore)
          return prevPage?.jobs[(prevPage?.jobs.length || 0) - 1]
        return undefined
      },
    }
  )

export default useGetScheduledNotifications
