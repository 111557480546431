/**
 * @file Date input, time zone specific
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import { useController } from 'react-hook-form'
import { Red } from 'src/constants/colors'

const Container = styled.div`
  background-color: #ffffff1a;
  border-radius: 8px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  border: 4px solid ${Red};
  border-color: ${({ error }) => (error ? Red : 'transparent')};
  border-radius: ${({ error }) => (error ? '8px 8px 0 0' : '8px')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  .react-datepicker__input-container {
    height: 100%;

    input {
      height: 100%;
      width: 100%;
      color: white;
      background-color: transparent;
      outline: none;
      border: 0;
      padding-left: 15px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
    }
  }
`

const Error = styled.div`
  background-color: ${Red};
  padding: 5px 15px 10px 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
`

const DateInput = ({
  required = false,
  disabled = false,
  defaultValue,
  control,
  name,
  placeholder = '',
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
    rules: { required },
    shouldUnregister: true,
  })

  const transform = {
    input: v => {
      if (!v) return v

      const offsetDifference = dayjs().tz().utcOffset() - dayjs().utcOffset()
      return dayjs(v).add(offsetDifference, 'minute').toDate()
    },
    output: v => {
      if (!v) return v

      const offsetDifference = dayjs().tz().utcOffset() - dayjs().utcOffset()
      return dayjs(v).subtract(offsetDifference, 'minute').toDate()
    },
  }

  return (
    <>
      <Container disabled={disabled} error={fieldState.error}>
        <DatePicker
          placeholderText={placeholder}
          showTimeSelect
          dateFormat="Pp"
          disabled={disabled}
          // minDate={getMinDateTime()}
          // minTime={getMinDateTime()}
          // maxTime={dayjs().endOf('day').toDate()}

          selected={transform.input(field.value)}
          onChange={e => field.onChange(transform.output(e))}
        />
        {field.value && <p>{dayjs.tz().format('z')}</p>}
      </Container>
      {fieldState.error && (
        <Error>
          <span>{fieldState.error.message}</span>
        </Error>
      )}
    </>
  )
}

DateInput.propTypes = {
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

export default DateInput
