/**
 * @file Use get event query
 * @author Jordan Stipp
 */

import { useQuery } from 'react-query'
import { eventKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const getEvent = async id => api.get(`event/${id}`).json()

const useGetEvent = id =>
  useQuery(eventKeys.detail(id), async () => getEvent(id), { enabled: !!id })

export default useGetEvent
