/**
 * @file Tiny Text Input Component, to be used in inventory, to be reworked in the future
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useController } from 'react-hook-form'
import { Red } from 'src/constants/colors'

const Container = styled.div`
  border-radius: 8px;
  background-color: #ffffff1a;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 4px solid ${Red};
  border-color: ${({ error }) => (error ? Red : 'transparent')};
  border-radius: ${({ error }) => (error ? '8px 8px 0 0' : '8px')};
  background-color: ${({ disabled }) =>
    disabled ? 'transparent' : '#ffffff1a'};

  > input {
    opacity: 1;
    height: 100%;
    width: 100%;
    outline: none;
    border: 0;
    color: white;
    background-color: transparent;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 1.133rem;
    margin: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`

const TinyInput = ({
  type, // temporary measure for price tags
  disabled = false,
  loading = false,
  required = false,
  inputProps = {},
  placeholder = '',
  defaultValue = '',
  control,
  name,
  style = {},
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
    rules: { required },
  })

  const transform = {
    input: v => {
      if (type === 'price') return (v || 0) / 100
      return v
    },
    output: v => {
      if (type === 'price') return (v || 0) * 100
      return v
    },
  }

  return (
    <Container disabled={disabled} error={fieldState.error} style={style}>
      <input
        {...inputProps}
        placeholder={placeholder}
        disabled={disabled || loading}
        {...field}
        onChange={e => field.onChange(transform.output(e.target.value))}
        value={transform.input(field.value)}
      />
    </Container>
  )
}

TinyInput.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

export default TinyInput
