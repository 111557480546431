import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { Purple } from 'src/constants/colors'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import useGetEvent from 'src/hooks/query/event/useGetEvent'
import useEventEmails from 'src/hooks/query/event/useEventEmails'
import useEventPhoneNumbers from 'src/hooks/query/event/useEventPhoneNumbers'
import useGetEventAttendees from 'src/hooks/query/event/useGetEventAttendees'
import useGetEventSeriesInstances from 'src/hooks/query/event/useGetEventSeriesInstances'
import useEventAttendeesCSV from 'src/hooks/query/event/useEventAttendeesCSV'
import fileDownload from 'js-file-download'
import Table from 'src/componentsV2/molecules/Table'
import { AnimatePresence } from 'framer-motion'
import MetadataRow from '../Drop/MetadataRow'
import EventModal from './EventModal'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 40px;
`

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  height: 40px;

  > input {
    background-color: #262627;
    outline: none;
    border: 0;
    border-radius: 12px;
    padding-left: 15px;
    width: 250px;
    color: white;
  }

  > button {
    margin-left: 15px;
    outline: none;
    background-color: ${Purple};
    border: 0;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    padding: 0 30px;
  }
`

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
`

const PreviewProperties = styled.div``

const Image = styled.img`
  height: 230px;
  background-color: red;
  margin-right: 15px;
  border-radius: 8px;
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;
`

const TableTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const TableTitle = styled.h3`
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
`

const EventAction = styled.p`
  cursor: pointer;
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  color: ${Purple};
`

const LoadMoreButton = styled.div`
  color: ${Purple};
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  padding-top: 10px;
  padding-left: 5px;
  cursor: pointer;
`

const EventDetails = () => {
  const searchInputRef = useRef(null)
  const navigate = useNavigate()
  const params = useParams()
  const eventID = params?.id
  const infiniteTriggerRef = useRef(null)
  const { data: eventData } = useGetEvent(eventID)
  const eventEmailsMutation = useEventEmails()
  const eventPhoneNumbersMutation = useEventPhoneNumbers()
  const eventAttendeesCSV = useEventAttendeesCSV()
  const event = eventData?.event

  // TODO: SEPARATE TABLES INTO TWO ORGANISMS WITH THEIR OWN HOOKS INSTEAD OF THIS
  let hook
  if (event?.isSeries) {
    hook = useGetEventSeriesInstances
  } else {
    hook = useGetEventAttendees
  }
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = hook(eventID)

  const [activeEventSeriesInstance, setActiveEventSeriesInstance] =
    useState(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (hasNextPage && infiniteTriggerRef.current && !isFetchingNextPage) {
      const observer = new IntersectionObserver(
        entries =>
          entries.forEach(entry => entry.isIntersecting && fetchNextPage()),
        { rootMargin: '0px', threshold: 1.0 }
      )

      const el = infiniteTriggerRef.current

      observer.observe(el)
      return () => observer.unobserve(el)
    }
    return () => {}
  }, [hasNextPage, fetchNextPage, isFetchingNextPage])

  const handleEventSelect = useCallback(
    e => {
      setActiveEventSeriesInstance(e)
      setShowModal(true)
    },
    [setActiveEventSeriesInstance]
  )

  const handleSearchClick = () => {
    navigate(`/event/${searchInputRef.current.value}`, {
      replace: searchInputRef.current.value === eventID,
    })
  }

  const handleCopyEmails = async () => {
    const toastID = toast.loading('Retrieving Emails')
    try {
      const { values } = await eventEmailsMutation.mutateAsync(eventID)
      navigator.clipboard.writeText(values.join(','))
      toast.success('Copied Emails', { id: toastID })
    } catch (e) {
      toast.error('Failed', { id: toastID })
    }
  }

  const handleCopyNumbers = async () => {
    const toastID = toast.loading('Retrieving Phone Numbers')
    try {
      const { values } = await eventPhoneNumbersMutation.mutateAsync(eventID)
      navigator.clipboard.writeText(values.join(','))
      toast.success('Copied Phone Numbers', { id: toastID })
    } catch (e) {
      toast.error('Failed', { id: toastID })
    }
  }

  const handleDownloadCSV = async () => {
    const toastID = toast.loading('Downloading CSV')
    try {
      const blob = await eventAttendeesCSV.mutateAsync(eventID)
      toast.success('Downloaded CSV', { id: toastID })
      fileDownload(
        blob,
        `${event?.title.replace(/\s/g, '_')}-attendees-${dayjs().format(
          'YYYY-MM-DDTHH-mm-ss'
        )}.csv`
      )
    } catch (e) {
      toast.error('Failed', { id: toastID })
    }
  }

  const attendeeColumns = useMemo(
    () => [
      { Header: 'User ID', accessor: '_id' },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      { Header: 'Phone Number', accessor: 'phoneNumber' },
    ],
    []
  )

  const instanceColumns = useMemo(
    () => [
      { Header: 'Title', accessor: 'title' },
      {
        Header: 'Start Time',
        accessor: v =>
          v.startDate && dayjs(v.startDate).tz().format('DD MMM YYYY h:mma z'),
      },
      {
        Header: 'End Time',
        accessor: v =>
          v.endDate && dayjs(v.endDate).tz().format('DD MMM YYYY h:mma z'),
      },
    ],
    []
  )

  if (!event) return <p style={{ paddingTop: 20 }}>No event selected yet</p>

  return (
    <Container>
      <SearchBar>
        <input
          ref={searchInputRef}
          placeholder="Search by ID"
          defaultValue={eventID}
        />
        <button type="button" onClick={handleSearchClick}>
          <p>
            <strong>Search</strong>
          </p>
        </button>
      </SearchBar>
      <PreviewContainer>
        <Image src={event?.flyer} />
        <PreviewProperties>
          <MetadataRow title="Title" content={event.title} />
          <MetadataRow
            title="Start Date"
            content={
              event.startDate &&
              dayjs(event.startDate).tz().format('DD MMM YYYY hh:mma z')
            }
          />
          <MetadataRow
            title="End Date"
            content={
              event.endDate &&
              dayjs(event.endDate).tz().format('DD MMM YYYY hh:mma z')
            }
          />
          {/* <MetadataRow
            title="Total Attending"
            content={event.rsvps.toString()}
          /> */}
        </PreviewProperties>
      </PreviewContainer>
      <TableContainer>
        {event?.isSeries ? (
          data && (
            <>
              <Table
                columns={instanceColumns}
                data={data}
                onSelect={handleEventSelect}
                activeID={activeEventSeriesInstance?.id}
              />
              <AnimatePresence>
                {showModal && (
                  <EventModal activeEvent={activeEventSeriesInstance} />
                )}
              </AnimatePresence>
            </>
          )
        ) : (
          <>
            <TableTitleContainer>
              <TableTitle>Guest List</TableTitle>
              <EventAction onClick={handleCopyEmails}>Copy Emails</EventAction>
              <EventAction onClick={handleCopyNumbers}>
                Copy Phone Numbers
              </EventAction>
              <EventAction onClick={handleDownloadCSV}>
                Download as CSV
              </EventAction>
            </TableTitleContainer>
            {data && <Table columns={attendeeColumns} data={data} />}
            <div ref={infiniteTriggerRef} />
          </>
        )}
      </TableContainer>
      {hasNextPage && (
        <LoadMoreButton onClick={fetchNextPage}>Load More</LoadMoreButton>
      )}
    </Container>
  )
}

export default EventDetails
