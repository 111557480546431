/**
 * @file Select Input component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { useController } from 'react-hook-form'
import { DarkerGray, DarkGray } from 'src/constants/colors'

const customStyles = {
  container: provided => ({
    ...provided,
  }),
  control: provided => ({
    ...provided,
    backgroundColor: '#ffffff1a',
    border: 0,
    minHeight: 50,
    padding: '7px 0 7px 10px',
    width: '100%',
    borderRadius: 8,
    boxShadow: 'none',
  }),
  input: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    opacity: 0.4,
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    padding: 15,
    backgroundColor: state.isFocused ? DarkerGray : 'transparent',

    ':active': {
      ...provided[':active'],
      backgroundColor: state.isFocused ? DarkerGray : 'transparent',
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: DarkerGray,
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: 'white',
    fontStyle: 'normal',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '0.8rem',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: DarkGray,
  }),
  menuList: provided => ({
    ...provided,
    borderRadius: 8,
  }),
}

const SelectInput = ({
  options,
  required = false,
  defaultValue,
  placeholder = '',
  name,
  control,
}) => {
  const transform = {
    input: v => (v ? { label: v, value: v } : null),
    output: v => v.value,
  }

  const { field } = useController({
    control,
    name,
    defaultValue,
    rules: { required },
  })

  return (
    <Select
      styles={customStyles}
      options={options}
      placeholder={placeholder}
      onChange={v => field.onChange(transform.output(v))}
      onBlur={field.onBlur}
      value={transform.input(field.value)}
      name={field.name}
      ref={field.ref}
    />
  )
}

SelectInput.propTypes = {
  options: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  required: PropTypes.bool,
  defaultValue: PropTypes.string, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

export default SelectInput
