import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Purple } from 'src/constants/colors'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

const FullWidthContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0%;
  top: 0%;
  transform: translate(-50%, -50);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  pointer-events: none;
  padding-bottom: 60px;
`

const Container = styled.div`
  width: 369px;
  background-color: #262627;
  border-radius: 25px;
  padding: 20px 10px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

const ModalTitle = styled.h3`
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  padding: 0px 0px 10px 0px;
`

const EventTitle = styled.h5`
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 5px;
`

const Time = styled.p`
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  padding: 5px 0px;
`

const Button = styled.button`
  height: 40px;
  padding: 10px 16px 10px 16px;
  margin-top: 5px;
  background-color: ${Purple};
  border: none;
  border-radius: 40px;
  color: white;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`

const EventModal = memo(({ activeEvent }) => {
  const navigate = useNavigate()
  return (
    <FullWidthContainer
      initial={{ top: '100%' }}
      animate={{ top: '0%' }}
      exit={{ top: '100%' }}
    >
      <Container>
        <ModalTitle>Selected</ModalTitle>
        <EventTitle>{activeEvent?.title}</EventTitle>
        <Time>
          <b>Starts: </b>
          {dayjs(activeEvent?.startDate).tz().format('DD MMM YYYY hh:mma')}
        </Time>
        <Time>
          <b>Ends: </b>
          {dayjs(activeEvent?.endDate).tz().format('DD MMM YYYY hh:mma')}
        </Time>
        <Button type="button" onClick={() => navigate(activeEvent?.id)}>
          View Details
        </Button>
      </Container>
    </FullWidthContainer>
  )
})

EventModal.propTypes = {
  activeEvent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
}

export default EventModal
