/**
 * @file New Drop Management Page
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { NavLink, Outlet, useSearchParams } from 'react-router-dom'
import Button from 'src/componentsV2/atoms/Button'
import useGetDrop from 'src/hooks/query/drop/useGetDrop'
import DropDetailsMenu from '../organisms/DropDetailsMenu'
import InventoryMenu from '../organisms/InventoryMenu'
import DropSearchBar from '../molecules/DropSearchBar'
import DiscoPartnerMenu from '../organisms/DiscoPartnerMenu'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  padding: 40px 0px 0px 40px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
`

const Nav = styled.nav`
  display: flex;
  margin-top: 30px;

  > * {
    margin-right: 30px;
    color: white;
    text-decoration: none;
    opacity: 0.4;
  }

  .active {
    opacity: 1;
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  padding-right: 40px;
`

const Drop = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleAddClick = () => {
    setSearchParams({ new: true })
  }

  const currentDropID = searchParams.get('selected')
  const showInventory = searchParams.get('showInventory')
  const showDiscoPartner = searchParams.get('showDiscoPartner')
  const isNewDrop = searchParams.get('new')

  const { data } = useGetDrop(currentDropID)
  const drop = data?.drop

  return (
    <Container>
      <Content>
        <Header>
          <Title>
            <h2 style={{ marginRight: 20 }}>Drops</h2>
            <Button size="medium" onClick={handleAddClick}>
              + Add
            </Button>
          </Title>
          <DropSearchBar
            onSelect={dropID => setSearchParams({ selected: dropID })}
          />
        </Header>
        <Nav>
          <NavLink to="active">
            <h4>Active</h4>
          </NavLink>
          <NavLink to="draft">
            <h4>Draft</h4>
          </NavLink>
        </Nav>
        <InnerContainer>
          <Outlet />
        </InnerContainer>
      </Content>
      {!(showInventory || showDiscoPartner) && currentDropID && drop && (
        <DropDetailsMenu drop={drop} />
      )}
      {isNewDrop && <DropDetailsMenu />}
      {showInventory && currentDropID && (
        <InventoryMenu dropID={currentDropID} />
      )}
      {showDiscoPartner && currentDropID && (
        <DiscoPartnerMenu dropID={currentDropID} />
      )}
    </Container>
  )
}

export default Drop
