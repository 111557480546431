/**
 * @file Update bookingV2 hook
 * @author Alwyn Tan
 */

import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { bookingV2Keys } from 'src/query-key-factory'

const updateBookingV2 = async (id, fields) =>
  (await axios.put(`/order/v2/booking/${id}`, fields)).data

const useUpdateBookingV2 = id => {
  const queryClient = useQueryClient()

  return useMutation(async fields => updateBookingV2(id, fields), {
    onSuccess: () => {
      queryClient.invalidateQueries(bookingV2Keys.detail(id))
      queryClient.invalidateQueries(bookingV2Keys.lists())
    },
  })
}

export default useUpdateBookingV2
