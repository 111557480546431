/**
 * @file Auth Screen
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

import useRequestLogin from 'src/hooks/query/user/useRequestLogin'
import useLogin from 'src/hooks/query/user/useLogin'

import discoman from 'src/images/discoman.png'
import TextInput from '../molecules/inputs/TextInput'
import PhoneInput from '../molecules/inputs/PhoneInput'
import Button from '../atoms/Button'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`

const Discoman = styled.div`
  background-image: url(${({ src }) => src});
  background-size: auto;
  background-repeat: no-repeat;
  height: 120px;
  width: 120px;
  margin-bottom: 15px;
`

const Form = styled.form`
  width: 335px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > *:first-child {
    margin-bottom: 15px;
  }
`

const Auth = () => {
  const [codeRequested, setCodeRequested] = useState(false)
  const { control, formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
  })

  const requestLoginMutation = useRequestLogin()
  const loginMutation = useLogin()

  const onSubmit = async ({ phoneNumber, code }) => {
    if (!codeRequested) {
      try {
        const { success } = await requestLoginMutation.mutateAsync(phoneNumber)
        if (success) setCodeRequested(true)
      } catch (e) {
        setError('phoneNumber', {
          type: 'manual',
          message: e.message,
        })
      }
    } else {
      try {
        await loginMutation.mutateAsync({ phoneNumber, code })
      } catch (e) {
        setError('code', {
          type: 'manual',
          message: e.message,
        })
      }
    }
  }

  return (
    <Container>
      <Discoman src={discoman} />
      <h1 style={{ paddingBottom: 25 }}>DISCO admin</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {!codeRequested ? (
          <PhoneInput
            control={control}
            name="phoneNumber"
            placeholder="Enter Phone Number"
            required
          />
        ) : (
          <TextInput
            required
            control={control}
            name="code"
            size="large"
            placeholder="Enter Code"
            inputProps={{
              autoComplete: 'one-time-code',
              maxLength: 4,
            }}
          />
        )}
        <Button
          size="large"
          disabled={
            !formState.isValid ||
            requestLoginMutation.isLoading ||
            Object.keys(formState.errors).length > 0
          }
        >
          {!codeRequested ? 'Next' : 'Login'}
        </Button>
      </Form>
    </Container>
  )
}

export default Auth
