/**
 * @file Get EventsSeriesInstances query
 * @author Jordan Stipp
 */

import { useInfiniteQuery } from 'react-query'
import { eventKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const getEventSeriesInstances = async (seriesID, prevItem) => {
  const searchParams = { ...prevItem }
  return api.get(`event/series/${seriesID}`, { searchParams }).json()
}

const useGetEventSeriesInstances = seriesID =>
  useInfiniteQuery(
    eventKeys.eventSeriesInstanceList(seriesID),
    async ({ pageParam }) => getEventSeriesInstances(seriesID, pageParam),
    {
      select: data =>
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.events], []),
      getNextPageParam: prevPage => {
        if (prevPage?.canLoadMore) {
          return {
            prevID: prevPage?.events[(prevPage?.events.length || 0) - 1]?.id,
            prevNextAvailableStartDate:
              prevPage?.events[(prevPage?.events.length || 0) - 1]
                ?.nextAvailableStartDate,
          }
        }
        return undefined
      },
    }
  )
export default useGetEventSeriesInstances
