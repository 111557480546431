import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useMakeDiscoPartner from 'src/hooks/query/drop/useMakeDiscoPartner'
import toast from 'react-hot-toast'
import { DarkerGray } from 'src/constants/colors'
import { useForm } from 'react-hook-form'
import useGetDrop from 'src/hooks/query/drop/useGetDrop'
import { DEAL_TYPES } from 'src/constants/drop'
import useUpdateDiscoPartner from 'src/hooks/query/drop/useUpdateDiscoPartner'
import Button from '../atoms/Button'
import FormItem from '../molecules/FormItem'
import TextInput from '../molecules/inputs/TextInput'
import SelectInput from '../molecules/inputs/SelectInput'

const Container = styled.div`
  padding: 40px;
  background-color: ${DarkerGray};
  width: 410px;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: auto;
  gap: 10px;
`

const Form = styled.form`
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`

const DiscoPartnerMenu = ({ dropID }) => {
  const { data: dropData } = useGetDrop(dropID)
  const { control, handleSubmit, reset } = useForm()
  const drop = dropData?.drop
  const makeDiscoPartnerMutation = useMakeDiscoPartner(dropID)
  const updateDiscoPartnerMutation = useUpdateDiscoPartner(dropID)

  useEffect(() => {
    if (!drop)
      reset({ venueShortCode: '', activeDealType: '', sharerDealType: '' })
    else
      reset({
        venueShortCode: drop?.venueShortCode,
        activeDealType: drop?.activeDeal?.type,
        sharerDealType: drop?.sharerDealType,
      })
  }, [drop, reset])

  const dealOptions = useMemo(
    () => Object.values(DEAL_TYPES).map(v => ({ label: v, value: v })),
    []
  )

  const onSubmit = async values => {
    const toastID = toast.loading('Loading')

    try {
      if (drop?.isDiscoPartner)
        await updateDiscoPartnerMutation.mutateAsync({
          activeDealType: values.activeDealType,
          sharerDealType: values.sharerDealType,
        })
      else
        await makeDiscoPartnerMutation.mutateAsync({
          venueShortCode: values.venueShortCode,
          activeDealType: values.activeDealType,
          sharerDealType: values.sharerDealType,
        })

      toast.success('Success', { id: toastID })
    } catch (e) {
      toast.error('Failed', { id: toastID })
    }
  }

  return (
    <Container>
      <h4>{drop?.title}</h4>
      <h5>Make Disco Partner</h5>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormItem
          title="Venue Short Code"
          input={
            <TextInput
              name="venueShortCode"
              defaultValue={drop?.venueShortCode}
              control={control}
              required
              placeholder="Enter Venue Short Code"
              disabled={drop?.isDiscoPartner}
            />
          }
        />
        <FormItem
          title="Receiver Deal Type"
          input={
            <SelectInput
              name="activeDealType"
              defaultValue={drop?.activeDeal?.type}
              control={control}
              options={dealOptions}
              placeholder="Choose Deal"
              required
            />
          }
        />
        <FormItem
          title="Sharer Deal Type"
          input={
            <SelectInput
              name="sharerDealType"
              defaultValue={drop?.sharerDealType}
              control={control}
              options={dealOptions}
              placeholder="Choose Deal"
              required
            />
          }
        />
        <Button disabled={makeDiscoPartnerMutation.isLoading}>Submit</Button>
      </Form>
    </Container>
  )
}

DiscoPartnerMenu.propTypes = {
  dropID: PropTypes.string.isRequired,
}

export default DiscoPartnerMenu
