/**
 * @file Phone Input for Auth
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useController } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { Red } from 'src/constants/colors'
import Spinner from 'src/componentsV2/atoms/Spinner'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const InputContainer = styled.div`
  background-color: #ffffff1a;
  width: 335px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
  height: 62px;
  border: 4px solid ${Red};
  border-color: ${({ error }) => (error ? Red : 'transparent')};
  border-radius: ${({ error }) => (error ? '8px 8px 0 0' : '8px')};

  > input {
    height: 100%;
    width: 100%;
    outline: none;
    border: 0;
    color: white;
    background-color: transparent;
    padding: 18px 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 1.333rem;
  }
`

const Error = styled.div`
  background-color: ${Red};
  padding: 5px 15px 10px 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;

  > span {
    font-weight: 600;
    font-size: 14px;
  }
`

const PhoneInput = ({ control, name, loading, required }) => {
  const countryPrefix = '+1'
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue: '',
    rules: {
      required,
      validate: value =>
        isMobilePhone(value || '') || 'Enter a valid US phone number',
    },
  })

  // utility to remove the current prefix off the phone number if there is any
  const getNumWithoutPrefix = value =>
    value.startsWith(countryPrefix) ? value.replace(countryPrefix, '') : value

  const transform = {
    input: value => getNumWithoutPrefix(value),
    output: value =>
      value
        ? `${countryPrefix}${getNumWithoutPrefix(value).replace(/[^0-9]/g, '')}`
        : '',
  }

  // hide error colors + text if the user is still typing up to the desired phone text length
  const hideError = field.value.length < 12

  return (
    <Container>
      <InputContainer error={!hideError && fieldState.error}>
        <h5 style={{ marginRight: 15 }}>{countryPrefix}</h5>
        <NumberFormat
          format="(###) ### ####"
          mask=" "
          placeholder="Phone Number"
          {...field}
          value={transform.input(field.value)}
          onChange={e => field.onChange(transform.output(e.target.value))}
        />
        {loading && <Spinner style={{ right: 15 }} />}
      </InputContainer>
      {!hideError && fieldState.error && (
        <Error>
          <span>{fieldState.error.message}</span>
        </Error>
      )}
    </Container>
  )
}

PhoneInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  required: PropTypes.bool,
  loading: PropTypes.bool,
}

export default PhoneInput
