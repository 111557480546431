/**
 * @file Request Login Hook
 * @author Alwyn Tan
 */

import { useMutation } from 'react-query'
import api from 'src/utils/api'

const postRequestLogin = async phoneNumber =>
  api
    .post('user/request-login', {
      json: { phoneNumber, admin: true },
      credentials: 'include',
    })
    .json()

const useRequestLogin = () =>
  useMutation(phoneNumber => postRequestLogin(phoneNumber))

export default useRequestLogin
