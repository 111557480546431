/**
 * @file Query to get inventories
 * @author Alwyn Tan
 */

import { useQuery } from 'react-query'
import api from 'src/utils/api'
import { inventoryV2Keys } from 'src/query-key-factory'

const getInventories = async dropID =>
  api.get('inventory/v2', { searchParams: { dropID } }).json()

const useGetInventories = dropID =>
  useQuery(
    inventoryV2Keys.list({ dropID }),
    async () => getInventories(dropID),
    { enabled: !!dropID, select: data => data.inventories }
  )

export default useGetInventories
