/**
 * @file Use get STL query
 * @author Alwyn Tan
 */

import { useQuery } from 'react-query'
import { stlKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const getSTL = async id => api.get(`stl/${id}`).json()

const useGetSTL = id =>
  useQuery(stlKeys.detail(id), async () => getSTL(id), {
    enabled: !!id,
  })

export default useGetSTL
