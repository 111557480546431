/**
 * @file 404 component
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    padding-bottom: 12px;
  }
`

const FourOhFour = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <p>Page Not Found</p>
      <button onClick={() => navigate('/')} type="button">
        Return to home
      </button>
    </Container>
  )
}

FourOhFour.propTypes = {}

export default FourOhFour
