/**
 * @file Order Tabs that house the table logic
 * @author Alwyn Tan
 */

import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import toast from 'react-hot-toast'
import useUpdateWaitlistCode from 'src/hooks/query/waitlist-code/useUpdateWaitlistCode'
import useCreateWaitlistCode from 'src/hooks/query/waitlist-code/useCreateWaitlistCode'
import useGetWaitlistCodes from 'src/hooks/query/waitlist-code/useGetWaitlistCodes'
import { WAITLIST_CODE_STATUS } from 'src/constants/waitlist-code'
import WaitlistCodeTable from './WaitlistCodeTable'
import WaitlistCodeModal from './WaitlistCodeModal'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  padding-bottom: 40px;
`

const WaitlistCodeTab = ({ status = '' }) => {
  const infiniteTriggerRef = useRef(null)
  const updateWaitlistCodeMutation = useUpdateWaitlistCode()
  const createWaitlistCodeMutation = useCreateWaitlistCode()
  const [activeCode, setActiveCode] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [disableModal, setDisableModal] = useState(false)
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useGetWaitlistCodes(status)
  const waitlistCodes = data || []

  useEffect(() => {
    if (hasNextPage && infiniteTriggerRef.current && !isFetchingNextPage) {
      const observer = new IntersectionObserver(
        entries =>
          entries.forEach(entry => entry.isIntersecting && fetchNextPage()),
        { rootMargin: '0px', threshold: 1.0 }
      )

      const el = infiniteTriggerRef.current

      observer.observe(el)
      return () => observer.unobserve(el)
    }
    return () => {}
  }, [hasNextPage, fetchNextPage, isFetchingNextPage])

  const handleWaitlistCodeSelect = useCallback(
    code => {
      setActiveCode(code)
    },
    [setActiveCode]
  )

  const handleEditIconClick = () => setShowModal(!showModal)

  const handleCancel = () => setShowModal(false)

  const handleSubmit = async ({ code, usesLeft, isActive }) => {
    setShowModal(false)
    setDisableModal(true)
    const isActiveBool = isActive === WAITLIST_CODE_STATUS.ACTIVE

    const toastID = toast.loading(
      `${activeCode ? 'Updating' : 'Creating'} Waitlist Code`
    )
    try {
      if (activeCode) {
        await updateWaitlistCodeMutation.mutateAsync({
          id: activeCode.id,
          usesLeft,
          isActive: isActiveBool,
        })
      } else {
        await createWaitlistCodeMutation.mutateAsync({
          code,
          usesLeft,
          isActive: isActiveBool,
        })
      }
      toast.success(`Waitlist Code ${activeCode ? 'Updated' : 'Created'}`, {
        id: toastID,
      })
    } catch (e) {
      toast.error(
        `Failed to ${activeCode ? 'Update' : 'Create'} Waitlist Code`,
        { id: toastID }
      )
    }
    setDisableModal(false)
  }

  const columns = useMemo(
    () => [
      { Header: 'Code', accessor: 'code' },
      { Header: 'usesLeft', accessor: 'usesLeft' },
      { Header: 'useCount', accessor: 'useCount' },
      {
        Header: 'Status',
        accessor: ({ isActive }) =>
          isActive
            ? WAITLIST_CODE_STATUS.ACTIVE
            : WAITLIST_CODE_STATUS.INACTIVE,
      },
    ],
    []
  )

  return (
    <Container>
      <WaitlistCodeTable
        columns={columns}
        data={waitlistCodes}
        activeCode={activeCode?.code}
        onSelect={handleWaitlistCodeSelect}
        onEdit={handleEditIconClick}
      />
      <AnimatePresence>
        {showModal && (
          <WaitlistCodeModal
            activeCode={activeCode}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            disabled={disableModal}
          />
        )}
      </AnimatePresence>
      <div ref={infiniteTriggerRef} />
    </Container>
  )
}

WaitlistCodeTab.propTypes = {
  status: PropTypes.string,
}

export default WaitlistCodeTab
