/**
 * @file Auth Context wrapper
 * @author Alwyn Tan
 */

import React from 'react'

// set the defaults
const AuthContext = React.createContext({
  user: {},
  setUser: () => {},
})

export default AuthContext
