/**
 * @file Silent Login Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import AuthContext from 'src/contexts/AuthContext'
import api from 'src/utils/api'

const postSilentLogin = async () =>
  api
    .post('user/silent-login', {
      json: { admin: true },
      credentials: 'include',
      throwHttpErrors: false,
    })
    .json()

const useSilentLogin = () => {
  const { setUser } = useContext(AuthContext)

  return useMutation(async () => {
    const { accessToken, user } = await postSilentLogin()
    setUser({ ...user, accessToken })

    return { user }
  })
}

export default useSilentLogin
