/**
 * @file Creates inventoryV2 hook
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from 'react-query'
import { inventoryV2Keys } from 'src/query-key-factory'
import api from 'src/utils/api'

const createInventoryV2 = async (dropID, fields) =>
  api.post('inventory/v2', { json: { dropID, ...fields } }).json()

const useCreateInventoryV2 = id => {
  const queryClient = useQueryClient()

  return useMutation(async fields => createInventoryV2(id, fields), {
    onSuccess: ({ inventoryV2 }) => {
      queryClient.invalidateQueries(
        inventoryV2Keys.list({ dropID: inventoryV2.drop })
      )
    },
  })
}

export default useCreateInventoryV2
