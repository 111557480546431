/**
 * @file Updates a drop
 * @author Alwyn Tan
 */

import { serialize } from 'object-to-formdata'
import { useMutation, useQueryClient } from 'react-query'
import { dropKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const updateDrop = async (id, fields) =>
  api
    .put(`drop/${id}`, {
      body: serialize(fields, { indices: true }),
      timeout: 60000,
      prefixUrl: process.env.REACT_APP_API_URL,
    })
    .json()

const useUpdateDrop = id => {
  const queryClient = useQueryClient()

  return useMutation(async fields => updateDrop(id, fields), {
    onSuccess: () => {
      queryClient.invalidateQueries(dropKeys.lists())
    },
  })
}

export default useUpdateDrop
