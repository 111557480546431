/**
 * @file Button
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Purple } from 'src/constants/colors'
import Spinner from './Spinner'

const StyledButton = styled.button`
  background-color: ${({ type }) =>
    type === 'primary' ? Purple : '#ffffff1a'};
  border-radius: 31px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  justify-content: center;
  align-items: center;
  outline: none;
  color: white;
  border: 0;
  cursor: pointer;
`

const SmallButton = styled(StyledButton)`
  height: 40px;
  padding: 10px 16px;
`

const MediumButton = styled(StyledButton)`
  height: 48px;
  padding: 14px 24px;
  width: 100%;
`

const LargeButton = styled(StyledButton)`
  height: 62px;
  padding: 20px 24px;
  width: 100%;
`

const Button = ({
  size,
  children,
  loading,
  disabled,
  type,
  style,
  onClick,
}) => {
  switch (size) {
    case 'small':
      return (
        <SmallButton
          disabled={disabled}
          type={type}
          style={style}
          onClick={onClick}
        >
          {loading ? <Spinner /> : <p className="button2">{children}</p>}
        </SmallButton>
      )
    case 'medium':
      return (
        <MediumButton
          disabled={disabled}
          type={type}
          style={style}
          onClick={onClick}
        >
          {loading ? <Spinner /> : <p className="button2">{children}</p>}
        </MediumButton>
      )
    case 'large':
      return (
        <LargeButton
          disabled={disabled}
          type={type}
          style={style}
          onClick={onClick}
        >
          {loading ? <Spinner /> : <p className="button1">{children}</p>}
        </LargeButton>
      )

    default:
      return null
  }
}

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary']),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
}

Button.defaultProps = {
  size: 'large',
  children: 'Text',
  loading: false,
  disabled: false,
  type: 'primary',
  style: {},
  onClick: () => {},
}

export default Button
