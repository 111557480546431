/**
 * @file Booking details component
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import styled from 'styled-components'
import { DarkerGray, Green, Red } from 'src/constants/colors'
import Text from 'src/componentsV2/atoms/Text'
import Button from 'src/componentsV2/atoms/Button'
import useGetBookingV2 from 'src/hooks/query/bookingV2/useGetBookingV2'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { BOOKING_V2_STATUS } from 'src/constants/booking'
import EditBookingModal from './EditBookingModal'

const Container = styled.div`
  padding: 16px;
  background: ${DarkerGray};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const DottedRow = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 12px;
  border: 2px dashed rgba(255, 255, 255, 0.2);
`

const ProfileImage = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
`

const DropImage = styled.img`
  height: 80px;
  width: 50px;
  border-radius: 4px;
  object-fit: cover;
`

const Inventory = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background: #ffffff0d;
  border-radius: 12px;
  padding: 16px;
`

const BookingDetails = () => {
  const [showEditBookingModal, setShowEditBookingModal] = useState(false)
  const [searchParams] = useSearchParams()
  const bookingV2ID = searchParams.get('bookingV2ID')
  const { data: bookingV2 } = useGetBookingV2(bookingV2ID, {
    populate: ['user'],
  })

  const renderStatus = () => {
    switch (bookingV2.status) {
      case BOOKING_V2_STATUS.PENDING:
        return (
          <Text type="button2" style={{ color: '#FFA801' }}>
            Pending
          </Text>
        )
      case BOOKING_V2_STATUS.IN_PROGRESS:
        return (
          <Text type="button2" style={{ color: '#FFA801' }}>
            In Progress
          </Text>
        )
      case BOOKING_V2_STATUS.CONFIRMED:
        return (
          <Text type="button2" style={{ color: Green }}>
            Confirmed
          </Text>
        )
      case BOOKING_V2_STATUS.CANCELED:
        return (
          <Text type="button2" style={{ color: Red }}>
            Canceled
          </Text>
        )
      default:
        return <Text type="button2">Unknown</Text>
    }
  }

  if (!bookingV2) return null

  return (
    <>
      <Text type="h4" style={{ marginBottom: 16 }}>
        Details
      </Text>
      <Container>
        <Row>
          <Section>
            <Text type="h5">
              {dayjs(bookingV2.scheduledDate).tz().format('MMM DD YYYY, h:mma')}
            </Text>
            {renderStatus()}
          </Section>
          <Button size="small" onClick={() => setShowEditBookingModal(true)}>
            Edit Booking
          </Button>
        </Row>
        <Section>
          <Text type="button2">User Information</Text>
          <DottedRow>
            <ProfileImage src={bookingV2.user?.picture} />
            <div style={{ paddingLeft: 24 }}>
              <Text type="button2" style={{ paddingBottom: 6 }}>
                {bookingV2.user?.name}
              </Text>
              {bookingV2.user?.email && (
                <Text type="p1">{bookingV2.user?.email}</Text>
              )}
              {bookingV2.user?.phoneNumber && (
                <Text type="p1">{bookingV2.user?.phoneNumber}</Text>
              )}
            </div>
          </DottedRow>
        </Section>
        <Section>
          <Text type="button2">Drop Information</Text>
          <DottedRow>
            <DropImage src={bookingV2?.dropThumbnail} />
            <Text type="button2" style={{ paddingLeft: 24 }}>
              {bookingV2.dropTitle}
            </Text>
          </DottedRow>
        </Section>
        <Section>
          <Text type="button2">Items Requested</Text>
          {bookingV2?.inventories.map(inventory => (
            <Inventory key={inventory.inventory}>
              <div>
                <Text type="button2">{inventory.inventoryTitle}</Text>
                {inventory.inventoryDeal && (
                  <Text type="p1" style={{ opacity: 0.5 }}>
                    with {inventory.inventoryDeal?.title}
                  </Text>
                )}
              </div>
              <Text type="button2">x{inventory.quantity}</Text>
            </Inventory>
          ))}
        </Section>
        {showEditBookingModal && (
          <EditBookingModal
            bookingV2={bookingV2}
            onClose={() => setShowEditBookingModal(false)}
          />
        )}
      </Container>
    </>
  )
}

export default BookingDetails
