/**
 * @file Side Menu Tab Item Component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const StyledNavLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 40px;
  }

  > div {
    opacity: 0;
  }

  > p {
    opacity: 0.5;
    transition: 0.3s ease opacity;
  }

  &.active {
    > div {
      opacity: 1;
    }

    > p {
      opacity: 1;
    }
  }
`

const Indicator = styled.div`
  height: 24px;
  width: 3px;
  border-radius: 3px;
  background-color: white;
  transition: 0.3s ease opacity;
  margin-right: 20px;
`

const SideMenuTabItem = ({ to = '', title = '' }) => (
  <StyledNavLink to={to}>
    <Indicator />
    <p className="button2">{title}</p>
  </StyledNavLink>
)

SideMenuTabItem.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
}

export default SideMenuTabItem
