/**
 * @file Updates a STL item
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from 'react-query'
import { stlInventoryKeys, stlKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const updateSTLInventory = async (id, fields) =>
  api.put(`stl-inventory/${id}`, { json: fields }).json()

const useUpdateSTLInventory = id => {
  const queryClient = useQueryClient()

  return useMutation(async fields => updateSTLInventory(id, fields), {
    onSuccess: ({ stl }) => {
      queryClient.invalidateQueries(stlKeys.list())
      queryClient.invalidateQueries(stlInventoryKeys.list(stl.id))
    },
  })
}

export default useUpdateSTLInventory
