/**
 * @file Create Waitlist Code Hook
 * @author Jordan Stipp
 */

import { useMutation, useQueryClient } from 'react-query'
import { waitlistCodeKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const postCreateWaitlistCode = async waitlistCode => {
  const { code, usesLeft, isActive } = waitlistCode
  return api
    .post(`waitlist-code/`, { json: { code, usesLeft, isActive } })
    .json()
}

const useCreateWaitlistCode = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async waitlistCode => postCreateWaitlistCode(waitlistCode),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitlistCodeKeys.all)
      },
    }
  )
}

export default useCreateWaitlistCode
