/**
 * @file Get Passes query
 * @author Alwyn Tan
 */

import { useInfiniteQuery } from 'react-query'
import { passKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const getPasses = async (status, pageParam) =>
  api
    .get('pass', {
      searchParams: {
        status,
        showAllUsers: true,
        ...pageParam,
        populateUser: true,
      },
    })
    .json()

const useGetPasses = status =>
  useInfiniteQuery(
    passKeys.list(status),
    async ({ pageParam }) => getPasses(status, pageParam),
    {
      select: data =>
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.passes], []),
      getNextPageParam: prevPage => {
        if (prevPage.canLoadMore) {
          const prevPass = prevPage.passes[(prevPage?.passes.length || 0) - 1]

          const pageParams = {}

          if (prevPass?.id) pageParams.prevID = prevPass.id
          if (prevPass?.expiryDate)
            pageParams.prevExpiryDate = prevPass.expiryDate

          return pageParams
        }
        return undefined
      },
    }
  )

export default useGetPasses
