/**
 * @file Text Input Component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useController } from 'react-hook-form'
import { Red } from 'src/constants/colors'
import Spinner from 'src/componentsV2/atoms/Spinner'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const LargeContainer = styled(Container)`
  > div:first-child {
    height: 62px;
  }

  input {
    font-weight: 600;
    font-size: 1.333rem;
  }

  span {
    font-weight: 600;
    font-size: 14px;
  }
`

const RegularContainer = styled(Container)`
  > div:first-child {
    height: 50px;
  }

  input {
    font-weight: 400;
    font-size: 1rem;
  }

  span {
    font-weight: 400;
    font-size: 12px;
  }
`

const InputContainer = styled.div`
  border-radius: 8px;
  background-color: #ffffff1a;
  max-width: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
  border: 4px solid ${Red};
  border-color: ${({ error }) => (error ? Red : 'transparent')};
  border-radius: ${({ error }) => (error ? '8px 8px 0 0' : '8px')};

  > input {
    height: 100%;
    width: 100%;
    outline: none;
    border: 0;
    color: white;
    background-color: transparent;
    font-family: Inter;
    font-style: normal;
  }
`

const Error = styled.div`
  background-color: ${Red};
  padding: 5px 15px 10px 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
`

const TextInput = ({
  size = 'regular',
  disabled = false,
  loading = false,
  required = false,
  inputProps = {},
  placeholder = '',
  defaultValue = '',
  control,
  name,
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
    rules: { required },
  })

  const renderInnerContent = () => (
    <>
      <InputContainer disabled={disabled} error={fieldState.error}>
        <input
          {...inputProps}
          placeholder={placeholder}
          disabled={disabled || loading}
          {...field}
        />
        {loading && <Spinner />}
      </InputContainer>
      {fieldState.error && (
        <Error>
          <span>
            {fieldState.error.type === 'required'
              ? 'This field is required'
              : fieldState.error.message}
          </span>
        </Error>
      )}
    </>
  )

  switch (size) {
    case 'regular':
      return <RegularContainer>{renderInnerContent()}</RegularContainer>

    case 'large':
    default:
      return <LargeContainer>{renderInnerContent()}</LargeContainer>
  }
}

TextInput.propTypes = {
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['regular', 'large']),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  inputProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

export default TextInput
