/**
 * @file Use Get Waitlisted users query
 * @author Alwyn Tan
 */

import { useInfiniteQuery } from 'react-query'
import { userKeys } from 'src/query-key-factory'
import api from 'src/utils/api'

const getWaitlistedUsers = async (searchQuery, pageParam) => {
  const searchParams = { ...pageParam, status: 'waitlisted' }
  if (searchQuery) searchParams.searchQuery = searchQuery

  return api.get('user', { searchParams }).json()
}

const useGetWaitlistedUsers = searchQuery =>
  useInfiniteQuery(
    userKeys.waitlistedList(searchQuery),
    async ({ pageParam }) => getWaitlistedUsers(searchQuery, pageParam),
    {
      select: data =>
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.users], []),
      getNextPageParam: prevPage => {
        if (prevPage.canLoadMore) {
          const prevUser = prevPage.users[(prevPage?.users.length || 0) - 1]
          return {
            prevID: prevUser?.id,
            prevUpdatedAt: prevUser?.updatedAt,
          }
        }
        return undefined
      },
    }
  )

export default useGetWaitlistedUsers
